import { Button, Drawer } from 'antd';
import './menu.css';
import { Link, } from "react-router-dom";
import { CloseOutlined, UserOutlined , FileSearchOutlined , DollarCircleOutlined , CalendarOutlined , PlusSquareOutlined, MobileOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Collapse, Space } from 'antd';
import { useState } from 'react';

const { Panel } = Collapse;

function Menu({ Open, setOpen }) {

    const Host = window.location.pathname.split("/");
    const Active = Host[2];

    function FiltraIcones(params) {
        if (params === 'UserOutlined ') {
            return <UserOutlined  />
        } else if (params === 'FileSearchOutlined ') {
            return <FileSearchOutlined  />
        } else if (params === 'CalendarOutlined ') {
            return <CalendarOutlined  />
        } else if (params === 'DollarCircleOutlined ') {
            return <DollarCircleOutlined  />
        } else if (params === 'MobileOutlined') {
            return <MobileOutlined />
        } else if (params === 'MenuUnfoldOutlined') {
            return <MenuUnfoldOutlined />
        } else {
            return <PlusSquareOutlined />
        };
    };

    const [MenuList, setMenuList] = useState([
        {
            Id: 1, Categ_Menu: 'Agenda', Icone: 'CalendarOutlined ', Ativo: true, Menus: [
                { Id: 1, Menu: 'Agenda', Icone: 'CalendarOutlined ', Ativo: true, ActiveUrl: "agenda", To: '/agenda' },
            ]
        },
        {
            Id: 2, Categ_Menu: 'Pacientes', Icone: 'UserOutlined ', Ativo: true, Menus: [
                { Id: 2, Menu: 'Pacientes', Icone: 'UserOutlined ', Ativo: true, ActiveUrl: "pacientes", To: '/pacientes' },
                { Id: 3, Menu: 'Prontuários', Icone: 'FileSearchOutlined  ', Ativo: true, ActiveUrl: "prontuarios", To: '/prontuarios' }
            ]
        },
        {
            Id: 3, Categ_Menu: 'Gestão', Icone: 'DollarCircleOutlined ', Ativo: true, Menus: [
                { Id: 4, Menu: 'Gestão', Icone: 'DollarCircleOutlined ', Ativo: true, ActiveUrl: "gestao", To: '/gestao' },
            ]
        }
    ]);

    return (
        <Drawer
            title={
                <h1 style={{ color: '#FFFFFF' }}>Acupuntura Pro</h1>
            }
            headerStyle={{ backgroundColor: "#1A2B52", height: 65 }}
            bodyStyle={{ padding: 0, backgroundColor: '#FFFFFF' }}
            closable={false}
            placement="left"
            width={350}
            open={Open}
            extra={
                <Button onClick={() => setOpen(!Open)} type="primary" size="large" style={{ width: 45, height: 45, borderRadius: 5, backgroundColor: '#F00000', fontSize: 21 }} icon={<CloseOutlined />} />
            }
        >

            <Collapse style={{ borderRadius: 0 }} expandIconposition="right">

                {MenuList.map((e, index) => (
                    <Panel style={{ width: '100%', borderRadius: 0, backgroundColor: '#F0F0F0', fontSize: 20, fontWeight: 'bold' }} header={
                        <>
                            {FiltraIcones(e.Icone)}
                            <text> {e.Categ_Menu}</text>
                        </>
                    } key={index}>
                        <Space style={{ flexDirection: 'column', width: '100%' }}>
                            {e.Menus.map((m, m_index) => (
                                <li key={m_index} className={Active === m.ActiveUrl ? "nav-text active" : "nav-text"}>
                                    <Link title={m.Menu} onClick={() => setOpen(!Open)} to={"/dashboard" + m.To}>
                                        <span >{FiltraIcones(m.Icone)}</span>
                                        <span style={{ marginLeft: 5 }}>{m.Menu}</span>
                                    </Link>
                                </li>
                            ))}
                        </Space>
                    </Panel>
                ))}

            </Collapse>

        </Drawer>
    );
}

export default Menu;
