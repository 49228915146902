import { useState } from 'react';
import {  Routes, Route } from 'react-router-dom';
import { Layout } from 'antd';
import Header from '../Components/Header';
import Menu from '../Components/Menu';

import Home from './../Pages/Home';
import Pacientes from '../Pages/Pacientes';
import Agenda from '../Pages/Agenda';
import Gestao from '../Pages/Gestao';
import Prontuarios from '../Pages/Prontuarios';
import PdfPacinetes from '../Pages/Pdf/pacinetes'
import Live from '../Pages/Live';


const Paginas = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/pacientes' element={<Pacientes />} />
      <Route path='/prontuarios' element={<Prontuarios />} />
      <Route path='/agenda' element={<Agenda />} />
      <Route path='/gestao' element={<Gestao />} />
      <Route path='/PdfPacinetes/:usuario' element={<PdfPacinetes />} />
      <Route path='/Live' element={<Live />} />
    </Routes>
  );
};

function Construtor() {

  const [Open, setOpen] = useState(false);

  return (
    <Layout style={{ overflow: 'hidden', overflowX: 'hidden', overflowY: 'hidden', backgroundColor: '#F0F0F0' }}>
      <Header Open={Open} setOpen={setOpen} />
      <Menu Open={Open} setOpen={setOpen} />
      <Paginas />
    </Layout>
  );
};

export default Construtor;
