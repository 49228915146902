import { useState, useEffect } from 'react'
import { Button, DatePicker, Table, Space, Drawer, Select, Input, AutoComplete, TimePicker, message, notification, Modal } from 'antd';
import { EyeOutlined, WhatsAppOutlined, DeleteOutlined } from '@ant-design/icons';
import Config from './../../Configuracoes/config';
import { PDFViewer } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import './home.css';
import axios from 'axios';
const moment = require('moment-timezone');


function Agenda() {

  const [Agendamentos, setAgendamentos] = useState([]);
  const [Pacientes, setPacientes] = useState([]);
  const [Etiquetas, setEtiquetas] = useState([]);
  const [MesAno, setMesAno] = useState('');
  const [Dia, setDia] = useState('');
  const [Novo, setNovo] = useState(false);
  const [Pdf, setPdf] = useState(false);

  const [Id, setId] = useState('');
  const [Titulo, setTitulo] = useState('');
  const [Descricao, setDescricao] = useState('');
  const [Status, setStatus] = useState('Aguardando');
  const [DataParametro, setDataParametro] = useState('');
  const [Data, setData] = useState('');
  const [Hora, setHora] = useState('');
  const [Paciente, setPaciente] = useState('');
  const [PacienteId, setPacienteId] = useState('');
  const [PacienteTelefone, setPacienteTelefone] = useState('');
  const [PacienteWhatsapp, setPacienteWhatsapp] = useState(false);
  const [Etiqueta, setEtiqueta] = useState('');


  function FormataStringDataRetorno(data) {
    var dia = data.split("/")[0];
    var mes = data.split("/")[1];
    var ano = data.split("/")[2];
    return ano + '-' + ("0" + mes).slice(-2) + '-' + ("0" + dia).slice(-2);
  };

  function InsertPaciente(params) {
    Pacientes.filter(e => e.Id === params)
      .map(e => {
        setPacienteTelefone(e.Telefone);
        setPacienteWhatsapp(e.Whatsapp);
        setPaciente(e.Nome);
      })
  };

  function AbrirAgendamento(Model) {

    setId(Model.Id);
    setTitulo(Model.Titulo);
    setDescricao(Model.Descricao);
    setStatus(Model.Status);
    setDataParametro(FormataStringDataRetorno(Model.Data))
    setData(moment(FormataStringDataRetorno(Model.Data)).tz("America/Sao_Paulo"));
    setHora(moment(FormataStringDataRetorno(Model.Data) + ' ' + Model.Hora).tz("America/Sao_Paulo"));
    setPacienteId(Model.Paciente);
    InsertPaciente(Model.Paciente);
    setEtiqueta(Model.Etiqueta);
    setNovo(true)
  };

  function ResetCampos() {
    setId('');
    setTitulo('');
    setDescricao('');
    setData('');
    setHora('');
    setStatus('Aguardando');
    setPaciente('');
    setPacienteId('');
    setPacienteTelefone('');
    setPacienteWhatsapp(false);
    setEtiqueta('');
  };


  async function Salvar(ModelId) {
    const Usuario = await localStorage.getItem('Id');
    axios({
      method: 'post',
      url: Config.Url + 'NovoAgenda',
      data: {
        "Titulo": Titulo,
        "Descricao": Descricao,
        "Status": Id !== '' ? 'Aguardando': Status,
        "Data": moment(Data.$d).tz("America/Sao_Paulo").format('DD/MM/YYYY'),
        "Hora": moment(Hora.$d).tz("America/Sao_Paulo").format('HH:mm'),
        "Usuario": Usuario,
        "Paciente": ModelId ? ModelId : PacienteId,
        "Etiqueta": Etiqueta,
      }
    }).then(e => {
      const json = e.data;
      if (json['sucesso'] === true) {
        TrazerAgendamentos()
        setNovo(false)
        ResetCampos()
        message.success('Agendamento realizado com sucesso');
      } else {
        TrazerAgendamentos()
        message.error('Ouve uma falha ao realizar o agendamento');
      };
    });

  };

  async function Editar() {
    const Usuario = await localStorage.getItem('Id');
    axios({
      method: 'put',
      url: Config.Url + 'EditarAgenda',
      data: {
        "Id": Id,
        "Titulo": Titulo,
        "Descricao": Descricao,
        "Status": Status,
        "Data": moment(Data.$d !== undefined && Data.$d !== null ? Data.$d : DataParametro).tz("America/Sao_Paulo").format('DD/MM/YYYY'),
        "Hora": moment(Hora.$d !== undefined && Hora.$d !== null ? Hora.$d : Hora).tz("America/Sao_Paulo").format('HH:mm'),
        "Usuario": Usuario,
        "Paciente": PacienteId,
        "Etiqueta": Etiqueta,
      }
    }).then(e => {
      const json = e.data;
      if (json['sucesso'] === true) {
        TrazerAgendamentos()
        setNovo(false)
        ResetCampos()
        message.success('Agendamento editado com sucesso');
      } else {
        TrazerAgendamentos()
        message.error('Ouve uma falha ao editar o agendamento');
      };
    });
  };

  async function CriarPronturario(Model) {
    const Usuario = await localStorage.getItem('Id');
    axios({
      method: 'post',
      url: Config.Url + 'NovoProntuariosRapido',
      data: {
        "Paciente": Model.Id,
        "Usuario": Usuario
      }
    }).then(e => {
      const json = e.data;
      if (json['sucesso'] === true) {
        setTimeout(() => {
          Salvar(Model.Id);
        }, 2000);
      };
    });
  };

  async function Cadastrar() {
    const Usuario = await localStorage.getItem('Id');
    axios({
      method: 'post',
      url: Config.Url + 'NovoPacientesRapido',
      data: {
        "Nome": Paciente,
        "Telefone": PacienteTelefone,
        "Whatsapp": PacienteWhatsapp,
        "Usuario": Usuario,
      }
    }).then(e => {
      const json = e.data;
      setTimeout(() => {
        CriarPronturario(json['result']);
        setPacienteId(json['result']['Id']);
        ListarPacientes();
      }, 2000);
    });
  };

  async function TrazerAgendamentos() {
    const Usuario = await localStorage.getItem('Id');
    axios({
      method: 'post',
      url: Config.Url + 'ListarAgenda',
      data: { "Usuario": Usuario }
    }).then(e => {
      const json = e.data;
      if (json['sucesso'] === true) {
        setAgendamentos(json['result']);
      } else {
        setAgendamentos([]);
      };
    });
  };

  async function ListarPacientes() {
    const Usuario = await localStorage.getItem('Id');
    axios({
      method: 'post',
      url: Config.Url + 'ListarPacientes',
      data: {
        Usuario
      }
    }).then(res => {
      if (res.data.sucesso) {
        setPacientes(res.data.result);
      } else {
        setPacientes([]);
      };
    });
  };

  async function TrazerEtiquetas() {
    axios({
      method: 'post',
      url: Config.Url + 'ListarEtiquetas',
    }).then(e => {
      const json = e.data;
      if (json['sucesso'] === true) {
        setEtiquetas(json['result']);
      } else {
        setEtiquetas([]);
      };
    });
  };

  useEffect(() => {
    TrazerAgendamentos();
    ListarPacientes()
    TrazerEtiquetas()
  }, []);

  function FiltrarPaciente(model) {
    const Result = Pacientes.filter(e => e.Id === model.Paciente);
    const Nome = Result.map(e => e.Nome).toString();
    const Telefone = Result.map(e => e.Telefone).toString();
    return { Nome, Telefone }
  };

  function FormataStringData(data) {
    var dia = data.split("/")[0];
    var mes = data.split("/")[1];
    var ano = data.split("/")[2];
    return ano + '-' + ("0" + mes).slice(-2) + '-' + ("0" + dia).slice(-2);
  }

  function FormataStringMesAno(data) {
    var mes = data.split("/")[1];
    var ano = data.split("/")[2];
    return ano + '-' + ("0" + mes).slice(-2);
  }

  function FiltrarLista() {
    if (MesAno !== null && MesAno !== '') {
      return Agendamentos.filter(e => (FormataStringMesAno(e.Data) === moment(MesAno).tz("America/Sao_Paulo").format('YYYY-MM')));
    }

    if (Dia !== null && Dia !== '') {
      return Agendamentos.filter(e => (FormataStringData(e.Data) === moment(Dia).tz("America/Sao_Paulo").format('YYYY-MM-DD')));
    }

    return Agendamentos.filter(e => FormataStringData(e.Data) === moment().tz("America/Sao_Paulo").format('YYYY-MM-DD'))
  };

  function FiltrarEtiqueta(params) {
    const Result = Etiquetas.filter(e => e.Id === params);
    const Cor = Result.map(e => e.Cor).toString();
    const Titulo = Result.map(e => e.Titulo).toString();
    return { Cor, Titulo }
  };

  async function AbrirWhatsapp(PacienteId) {
    const Result = Pacientes.filter(e => e.Id === PacienteId);
    let Telefone = Result.map(e => e.Telefone).toString();
    const Whatsapp = Result.map(e => e.Whatsapp).toString();
    const Pais = await localStorage.getItem('Pais');

    Telefone = Telefone.replace(/[ÀÁÂÃÄÅ]/g, '');
    Telefone = Telefone.replace(/[àáâãäå]/g, '');
    Telefone = Telefone.replace(/[ÈÉÊË]/g, '');
    Telefone = Telefone.replace(/[^a-z0-9]/gi, '');

    if (Whatsapp === 'true') {
      window.open('https://wa.me/' + Pais + Telefone, "_blank");
    }

  };

  function ExcluirAgendamento(Model) {
    api.destroy(Model.Id)
    axios({
      method: 'delete',
      url: Config.Url + 'DeletarAgenda',
      data: {
        "Id": Model.Id
      }
    }).then(e => {
      const json = e.data;
      if (json['sucesso'] === true) {
        message.success('Agendamento excluído com sucesso');
        TrazerAgendamentos();
      } else {
        message.error('Falha ao excluir agendamento');
        TrazerAgendamentos();
      };
    });
  };

  const [api, contextHolder] = notification.useNotification();

  function MensagemDeleteAgendamento(model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => ExcluirAgendamento(model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja excluir este registro ?',
      btn,
      key: model.Id
    });
  }

  const columns = [
    {
      title: 'ETIQUETA',
      dataIndex: 'Etiqueta',
      key: 'Etiqueta',
      render: (dataIndex, key) =>
        <div title={FiltrarEtiqueta(dataIndex)['Titulo']} style={{ width: 30, height: 30, borderRadius: 30, backgroundColor: FiltrarEtiqueta(dataIndex)['Cor'] }} />
    },
    {
      title: 'DATA',
      dataIndex: 'Data',
      key: 'Data',

    },
    {
      title: 'HORÁRIO',
      dataIndex: 'Hora',
      key: 'Hora',
    },
    {
      title: 'STATUS',
      dataIndex: 'Status',
      key: 'Status',
      render: (dataIndex, key) =>
        <text style={{ color: dataIndex === 'Aguardando' ? 'orange' : dataIndex === 'Compareceu' ? 'green' : 'red' }}>{dataIndex}</text>
    },
    {
      title: 'TÍTULO',
      dataIndex: 'Titulo',
      key: 'Titulo',
    },
    {
      title: 'PACIENTE',
      dataIndex: 'Paciente',
      key: 'Paciente',
      render: (dataIndex, key) =>
        <text>{FiltrarPaciente(key)['Nome']}</text>
    },
    {
      title: 'TELEFONE',
      dataIndex: 'Telefone',
      key: 'Telefone',
      render: (dataIndex, key) =>
        <a onClick={() => AbrirWhatsapp(key['Paciente'])}><text>{FiltrarPaciente(key)['Telefone']}</text></a>
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => AbrirAgendamento(key)} title='Editar' type="primary" shape="circle" icon={<EyeOutlined />} />
          <Button onClick={() => MensagemDeleteAgendamento(key)} title='Excluir' type="primary" danger shape="circle" icon={<DeleteOutlined />} />
        </Space>
    },
  ];
  return (
    <div className='ContainerHome'>
      {contextHolder}
      <div className='ContainerTableNav'>
        <Space>
          <Button type="primary" danger onClick={() => setPdf(true)}>PDF</Button>
          <Button type="primary" onClick={() => setNovo(true)}>ADICIONAR</Button>
        </Space>
        <div>
          <Space>
            <DatePicker onChange={(e) => setMesAno(e ? e.$d : null)} placeholder='Selecione o mês' format={'MM/YYYY'} style={{ width: 200 }} picker="month" size='large' />
            <DatePicker showToday={false} onChange={(e) => setDia(e ? e.$d : null)} placeholder='Selecione o dia' format={'DD/MM/YYYY'} style={{ width: 200 }} size='large' />
          </Space>
        </div>
      </div>

      <div className='ContainerTable'>
        <Table dataSource={FiltrarLista()} max columns={columns} />
      </div>


      <Drawer
        title={"Novo agendamento"}
        closable={false}
        width={480}
        placement="right"
        open={Novo}
        extra={
          <Space>
            <Button onClick={() => [setNovo(false), ResetCampos()]}>Fechar</Button>
            <Button style={{ backgroundColor: 'orange', color: '#fff' }} onClick={() => PacienteId === '' ? Cadastrar() : Salvar()}>Reagendar</Button>
            <Button type="primary" onClick={() => PacienteId === '' ? Cadastrar() : Id !== '' ? Editar() : Salvar()} >Salvar</Button>
          </Space>
        }>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ flexDirection: 'column', display: 'flex', width: '100%', marginBottom: 10 }}>
            <label style={{ marginLeft: 5 }}>Paciente</label>
            <AutoComplete options={Pacientes.map(e => ({ Id: e.Id, value: e.Nome, Telefone: e.Telefone, Whatsapp: e.Whatsapp }))} size={'large'} style={{ width: '100%', }} placeholder="Paciente"
              value={Paciente}
              onSelect={(p, e) => [setPaciente(e.value), setPacienteId(e.Id), setPacienteTelefone(e.Telefone), setPacienteWhatsapp(e.Whatsapp)]}
              onChange={(e) => [setPaciente(e), setPacienteId(''), setPacienteTelefone(''), setPacienteWhatsapp(false)]}
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ flexDirection: 'column', display: 'flex', width: '48%', marginBottom: 10 }}>
            <label style={{ marginLeft: 5 }}>Título</label>
            <Input value={Titulo} onChange={(e) => setTitulo(e.target.value)} size={'large'} style={{ width: '100%' }} placeholder="Título" />
          </div>
          <div style={{ flexDirection: 'column', display: 'flex', width: '48%', marginBottom: 10 }}>
            <label style={{ marginLeft: 5 }}>Telefone</label>
            <Input value={PacienteTelefone} onChange={(e) => setPacienteTelefone(e.target.value)} size={'large'} style={{ width: '100%' }} placeholder="Telefone" prefix={<Button onClick={() => Id !== '' ? AbrirWhatsapp(PacienteId) : setPacienteWhatsapp(!PacienteWhatsapp)} style={{ backgroundColor: PacienteWhatsapp ? 'green' : 'white', color: PacienteWhatsapp ? 'white' : 'green' }} icon={<WhatsAppOutlined />} />} />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ flexDirection: 'column', display: 'flex', width: '48%', marginBottom: 10 }}>
            <label style={{ marginLeft: 5 }}>Data</label>
            {Novo && <DatePicker onChange={(e) => setData(e === null ? '' : e)} closable={false} placeholder={Data !== '' ? moment(Data).tz("America/Sao_Paulo").format('DD/MM/YYYY') : 'Data'} size={'large'} style={{ width: '100%' }} format={'DD/MM/YYYY'} />}
          </div>
          <div style={{ flexDirection: 'column', display: 'flex', width: '48%', marginBottom: 10 }}>
            <label style={{ marginLeft: 5 }}>Horário</label>
            <TimePicker value={Hora} onChange={(e) => setHora(e === '' ? null : e)} placeholder='Horário' size={'large'} style={{ width: '100%' }} format={'HH:mm'} />
          </div>
        </div>

        <div style={{ flexDirection: 'column', display: 'flex', width: '102%', marginBottom: 10 }}>
          <label style={{ marginLeft: 5 }}>Etiqueta</label>
          <Select value={Etiqueta} onChange={(e) => setEtiqueta(e)} size={'large'} style={{ width: '100%' }} placeholder="Etiqueta" >
            <options value="">Etiqueta</options>
            {Etiquetas.map((e, index) => (
              <options value={e.Id}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: 10, marginRight: 5, height: 10, borderRadius: 5, backgroundColor: e.Cor }} />
                  <text>{e.Titulo}</text>
                </div>
              </options>
            ))}
          </Select>
        </div>

        {Id !== '' &&
          <div style={{ flexDirection: 'column', display: 'flex', width: '102%', marginBottom: 10 }}>
            <label style={{ marginLeft: 5 }}>Status</label>
            <Select value={Status} onChange={(e) => setStatus(e)} size={'large'} style={{ width: '100%' }} placeholder="Status" >
              <options value="">Etiqueta</options>
              <options value="Aguardando">Aguardando</options>
              <options value="Compareceu">Compareceu</options>
              <options value="Remarcou">Remarcou</options>
              <options value="Faltou">Faltou</options>
              <options value="Desistiu">Desistiu</options>
            </Select>
          </div>
        }

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between' }}>
          <div style={{ flexDirection: 'column', display: 'flex', width: '102%', marginBottom: 10 }}>
            <label style={{ marginLeft: 5 }}>Observação</label>
            <Input.TextArea value={Descricao} onChange={(e) => setDescricao(e.target.value)} size={'large'} autoSize={{ minRows: 3, maxRows: 10 }} style={{ width: '100%' }} placeholder="Observação" prefix={<Button />} />
          </div>
        </div>

      </Drawer>



      <Modal width={1000} open={Pdf} onCancel={() => setPdf(false)} footer={[]} closable={false}>
        <div style={{ height: 800 }}>
          <PDFViewer style={{ width: '100%', height: '100%' }}>
            <Document>
              <Page size="A4" style={styles.page}>
                <div style={{ width: '100%', padding: 25, height: 180, backgroundColor: '#3c5270', alignItems: 'center' }}>
                  <Text style={{ color: '#fff', fontWeight: 'bold' }}>{localStorage.getItem('Empresa')}</Text>
                  <View style={styles.section}>
                    <Text style={{ fontSize: 15, marginBottom: 10, color: '#fff' }}>Profissional: {localStorage.getItem('Nome')}</Text>

                    <Text style={{ fontSize: 15, marginBottom: 10, color: '#fff' }}>Telefone: {localStorage.getItem('Telefone')}</Text>

                    <Text style={{ fontSize: 15, marginBottom: 10, color: '#fff' }}>Cidade: {localStorage.getItem('Cidade')}</Text>

                    <Text style={{ fontSize: 15, marginBottom: 10, color: '#fff' }}>Gerado em: {moment().tz("America/Sao_Paulo").format('DD/MM/YYYY HH:mm:ss')}</Text>
                  </View>
                </div>

                <View style={styles.table}>
                  <View style={styles.row}>
                    <div style={{ width: '14.28%', height: 30, backgroundColor: '#545454', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontSize: 12, color: '#fff' }}>Etiqueta</Text>
                    </div>
                    <div style={{ width: '14.28%', height: 30, backgroundColor: '#545454', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontSize: 12, color: '#fff' }}>Data</Text>
                    </div>
                    <div style={{ width: '14.28%', height: 30, backgroundColor: '#545454', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontSize: 12, color: '#fff' }}>Horário</Text>
                    </div>
                    <div style={{ width: '14.28%', height: 30, backgroundColor: '#545454', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontSize: 12, color: '#fff' }}>Status</Text>
                    </div>
                    <div style={{ width: '14.28%', height: 30, backgroundColor: '#545454', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontSize: 12, color: '#fff' }}>Título</Text>
                    </div>
                    <div style={{ width: '14.28%', height: 30, backgroundColor: '#545454', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontSize: 12, color: '#fff' }}>Paciente</Text>
                    </div>  
                    <div style={{ width: '14.28%', height: 30, backgroundColor: '#545454', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontSize: 12, color: '#fff' }}>Telefone</Text>
                    </div>
                  </View>

                  {FiltrarLista().map((e, index) => (
                    <View style={styles.rows}>
                      <div style={{ width: '14.28%', height: 30, backgroundColor: FiltrarEtiqueta(e.Etiqueta)['Cor'], display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: 10, color: '#fff' }}>{FiltrarEtiqueta(e.Etiqueta)['Titulo']}</Text>
                      </div>
                      <div style={{ width: '14.28%', height: 30, backgroundColor: '#eee', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: 10, color: '#545454' }}>{e.Data}</Text>
                      </div>
                      <div style={{ width: '14.28%', height: 30, backgroundColor: '#eee', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: 10, color: '#545454' }}>{e.Hora}</Text>
                      </div>
                      <div style={{ width: '14.28%', height: 30, backgroundColor: '#eee', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: 10, color: '#545454' }}>{e.Status}</Text>
                      </div>
                      <div style={{ width: '14.28%', height: 30, backgroundColor: '#eee', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: 10, color: '#545454' }}>{e.Titulo}</Text>
                      </div>
                      <div style={{ width: '14.28%', height: 30, backgroundColor: '#eee', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: 10, color: '#545454' }}>{FiltrarPaciente(e)['Nome']}</Text>
                      </div>
                      <div style={{ width: '14.28%', height: 30, backgroundColor: '#eee', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: 10, color: '#545454' }}>{FiltrarPaciente(e)['Telefone']}</Text>
                      </div>
                    </View>
                  ))}

                </View>

              </Page>
            </Document>
          </PDFViewer>
        </div>
      </Modal>


    </div>
  );
};
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff'
  },
  section: {
    marginTop: 25,
    width: '100%',
  },
  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTop: '1px solid #EEE',
    paddingBottom: 8,
  },
  rows: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTop: '1px solid #EEE',
    marginBottom: 1

  }
});

export default Agenda;
