
import { useState, useEffect } from 'react'
import { Button, Table, DatePicker, Drawer, Input, Select, Space, message, notification, Modal } from 'antd';
import Config from './../../Configuracoes/config';
import axios from 'axios';
import { EyeOutlined, DeleteOutlined, SendOutlined } from '@ant-design/icons';
import './home.css';
import { PDFViewer } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
const moment = require('moment-timezone');

function Gestao() {

  const [Gestao, setGestao] = useState([]);
  const [Gestaoes, setGestaoes] = useState([]);
  const [GestaoFixa, setGestaoFixa] = useState([]);
  const [PacientesList, setPacientesList] = useState([]);
  const [Faturas, setFaturas] = useState([]);
  const [AReceber, setAReceber] = useState([]);
  const [DateSell, setDateSell] = useState(moment(new Date()).tz("America/Sao_Paulo").format('DD/MM/YYYY'));
  const [DateSellDay, setDateSellDay] = useState('');
  const [Novo, setNovo] = useState(false);
  const [NovoFixa, setNovoFixa] = useState(false);
  const [ADDNovoFixa, setADDNovoFixa] = useState(false);
  const [Receber, setReceber] = useState(false);

  const [Id, setId] = useState('');
  const [Titulo, setTitulo] = useState('');
  const [Descricao, setDescricao] = useState('');
  const [Valor, setValor] = useState('');
  const [Tipo, setTipo] = useState('');
  const [Data, setData] = useState('');
  const [FaturaCli, setFaturaCli] = useState('');
  const [Pdf, setPdf] = useState(false);

  function FormataStringData(data) {
    var dia = data.split("/")[0];
    var mes = data.split("/")[1];
    var ano = data.split("/")[2];
    return ano + '-' + ("0" + mes).slice(-2) + '-' + ("0" + dia).slice(-2);
  }

  function AbrirRegistro(model) {
    setId(model.Id);
    setTitulo(model.Titulo);
    setDescricao(model.Descricao);
    setValor(model.Valor);
    setTipo(model.Tipo);
    setData(FormataStringData(model.Data));
    setFaturaCli(model.Fatura);
    setNovo(true)
  };

  async function ListarPacientes() {
    const Usuario = await localStorage.getItem('Id');
    axios({
      method: 'post',
      url: Config.Url + 'ListarPacientes',
      data: {
        Usuario: Usuario
      }
    }).then(res => {
      if (res.data.sucesso === true) {
        setPacientesList(res.data.result)
      } else {
        setPacientesList([])
      };
    });

  };

  function formatarMoeda(params) {
    var valor = params;
    valor = valor + '';
    valor = parseInt(valor.replace(/[\D]+/g, ''));
    valor = valor + '';
    valor = valor < 99 ? valor.replace(/([0-9]{2})$/g, "0,$1") : valor.replace(/([0-9]{2})$/g, ",$1");

    if (valor.length > 6) {
      valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
    }
    return params === '' ? '0,00' : valor;
  };


  async function ListarFixa() {
    const Usuario = await localStorage.getItem('Id');
    axios({
      method: 'post',
      url: Config.Url + 'ListarGestaoFixa',
      data: {
        Usuario: Usuario
      }
    }).then(res => {
      if (res.data.sucesso === true) {
        setGestaoFixa(res.data.result)
      } else {
        setGestaoFixa([])
      };
    });
  };


  function FormataDataMesAno1() {
    const JsonData = DateSell.split('/');
    const Result = `${JsonData[2]}-${JsonData[1]}`
    return Result;
  };

  function FormataDataMesAno2(Data) {
    const JsonData = Data.split('/');
    const Result = `${JsonData[2]}-${JsonData[1]}`
    return Result;
  };

  function FormataDataMesAno1Dai() {
    const JsonData = DateSellDay.split('/');
    const Result = `${JsonData[0]}-${JsonData[2]}-${JsonData[1]}`
    return Result;
  };

  function FormataDataMesAno2Dai(Data) {
    const JsonData = Data.split('/');
    const Result = `${JsonData[0]}-${JsonData[2]}-${JsonData[1]}`
    return Result;
  };

  function TrazNome(Id) {
    const res = PacientesList.filter(e => e.Id === Id);
    const Nome = res.map(e => (e.Nome)).toString();
    return Nome;
  };

  async function ListarFaturas() {
    const Usuario = await localStorage.getItem('Id');

    axios({
      method: 'post',
      url: Config.Url + 'ListarFaturaCliAll',
      data: {
        Usuario: Usuario
      }
    }).then(res => {
      if (res.data.sucesso === true) {
        setFaturas(res.data.result)
      }
    })

    axios({
      method: 'post',
      url: Config.Url + 'ListarPacientes',
      data: { Usuario }
    }).then(res => {
      if (res.data.sucesso) {
        res.data.result
          .map(Model => {
            axios({
              method: 'post',
              url: Config.Url + 'ListarFaturaCli',
              data: {
                Paciente: Model.Id
              }
            }).then(res => {
              if (res.data.sucesso === true) {
                const Fatura = res.data.result;

                const res1 = Fatura.filter(e => {
                  if (e.Status_comp === 'Compareceu' && e.Status_pg === 'Não' && e.Cortesia === 'Não') {
                    return e
                  };
                });
                const res2 = Fatura.filter(e => {
                  if (e.Status_comp === 'Compareceu' && e.Status_pg === 'Sim' && LimpaCaracteresParseFloat(e.Valor_pg) < LimpaCaracteresParseFloat(e.Procedimento) && LimpaCaracteresParseFloat(e.Valor_pg) > 0) {
                    return e
                  };
                });
                const res3 = Fatura.filter(e => {
                  if (e.Status_comp === 'Compareceu' && e.Status_pg === 'Sim' && LimpaCaracteresParseFloat(e.Valor_pg) > LimpaCaracteresParseFloat(e.Procedimento)) {
                    return e
                  };
                });

                const Valor1 = res1.map(e => (LimpaCaracteresParseFloat(e.Procedimento)));
                const Valor2 = res2.map(e => (LimpaCaracteresParseFloat(e.Procedimento)));
                const Valor3 = res2.map(e => (LimpaCaracteresParseFloat(e.Valor_pg)));
                const Valor4 = res3.map(e => (LimpaCaracteresParseFloat(e.Valor_pg)));
                const Valor5 = res3.map(e => (LimpaCaracteresParseFloat(e.Procedimento)));

                var sum1 = 0;
                var sum2 = 0;
                var sum3 = 0;
                var sum4 = 0;
                var sum5 = 0;

                for (var i = 0; i < Valor1.length; i++) {
                  sum1 += Valor1[i];
                };
                for (var i = 0; i < Valor2.length; i++) {
                  sum2 += Valor2[i];
                };
                for (var i = 0; i < Valor3.length; i++) {
                  sum3 += Valor3[i];
                };
                for (var i = 0; i < Valor4.length; i++) {
                  sum4 += Valor4[i];
                };
                for (var i = 0; i < Valor5.length; i++) {
                  sum5 += Valor5[i];
                };

                const Diferenca = parseFloat(sum4) - parseFloat(sum5);
                const Soma = parseFloat(sum2) - parseFloat(sum3);
                const Calculo = (parseFloat(sum1) + parseFloat(Soma)) - parseFloat(Diferenca);
                const Result = Calculo <= 0 ? 0 : Calculo;

                if (Result > 0) {
                  var Debitos = AReceber;
                  const Sec = Debitos.filter(e => (e.Id === Model.Id));
                  if (Sec.length === 0) {
                    AReceber.push({ Id: Model.Id, Paciente: Model.Nome, Valor: Result });
                  }
                  if (AReceber.length !== Debitos.length) {
                    setAReceber(Debitos);
                  }
                }

              } else {
                console.log('Sem Faturas');
              };
            });
          });

      } else {
        console.log('Sem Pacientes');
      };
    });
  };

  //#region FiltrarDebitos
  function FiltrarDebitos() {
    const Valor = AReceber.map(e => (e.Valor));
    var sum = 0;
    for (var i = 0; i < Valor.length; i++) {
      sum += Valor[i];
    };
    return sum;
  };
  //#endregion

  async function Listar() {
    const Usuario = await localStorage.getItem('Id');
    axios({
      method: 'post',
      url: Config.Url + 'ListarGestao',
      data: {
        Usuario: Usuario
      }
    }).then(res => {
      if (res.data.sucesso === true) {
        setGestaoes(res.data.result);
        setGestao(res.data.result);
      } else {
        setGestaoes([]);
        setGestao([]);
      };
    });
  };

  function FormataDataMesAno(Data) {
    const JsonData = Data.split('/');
    const Result = `${JsonData[2]}-${JsonData[1]}`
    return Result;
  };

  const Reduzir = (acumulador, { Data, Descricao, Fatura, Id, Paciente, Status_pg, Tipo, Titulo, Valor, updatedAt }) => {
    const Date = FormataDataMesAno(Data);
    if (!acumulador[Date]) {
      acumulador[Date] = {
        Data,
        Json: [{ Data, Descricao, Fatura, Id, Paciente, Status_pg, Tipo, Titulo, Valor, updatedAt }]
      };
    } else {
      acumulador[Date].Json.push({ Data, Descricao, Fatura, Id, Paciente, Status_pg, Tipo, Titulo, Valor, updatedAt });
    }
    return acumulador;
  };
  const Agrupar = (Conjunto) => {
    return Object.values(Conjunto.reduce(Reduzir, {}));
  };
  const RetornoGestao = Agrupar(Gestaoes.filter(e => DateSellDay !== '' ? FormataDataMesAno2Dai(e.Data) === FormataDataMesAno1Dai() : FormataDataMesAno2(e.Data) === FormataDataMesAno1()));

  var meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro"
  ];

  function FormatarDataMes(str) {
    var partes = str.split('/').map(Number);
    var data = new Date(partes[2], partes[1] - 1, '01');
    var mes = meses[data.getMonth()];
    return [mes.toLowerCase(), data.getFullYear()].join(' ');
  };

  useEffect(() => {
    Listar()
    ListarFaturas()
    ListarPacientes()
    ListarFixa()
    setData(moment(new Date()).tz("America/Sao_Paulo").format('DD/MM/YYYY'));
  }, [])



  function LimpaCaracteresParseFloat(text) {
    const res = text.replace('R$', '').replace('.', '').replace(',', '.');
    return parseFloat(res);
  };

  function numberToReal(numero) {
    var numero = parseFloat(numero).toFixed(2).split('.');
    numero[0] = "R$ " + numero[0].split(/(?=(?:...)*$)/).join('.');
    return numero.join(',');
  };

  function Receita() {
    var filter = Gestao.filter(e => (e.Tipo === 'Receita' && FormataDataMesAno2(e.Data) === FormataDataMesAno1()));
    var filterD = Gestao.filter(e => (e.Tipo === 'Receita' && FormataDataMesAno2Dai(e.Data) === FormataDataMesAno1Dai()));
    var arr = DateSellDay !== '' ? filterD.map(e => (LimpaCaracteresParseFloat(e.Valor))) : filter.map(e => (LimpaCaracteresParseFloat(e.Valor)));
    var soma = 0;
    for (var i = 0; i < arr.length; i++) {
      soma += arr[i];
    };
    return numberToReal(soma);
  };

  function Despesa() {
    var filter = Gestao.filter(e => (e.Tipo === 'Despesa' && FormataDataMesAno2(e.Data) === FormataDataMesAno1()));
    var filterD = Gestao.filter(e => (e.Tipo === 'Despesa' && FormataDataMesAno2Dai(e.Data) === FormataDataMesAno1Dai()));
    var arr = DateSellDay !== '' ? filterD.map(e => (LimpaCaracteresParseFloat(e.Valor))) : filter.map(e => (LimpaCaracteresParseFloat(e.Valor)));
    var soma = 0;
    for (var i = 0; i < arr.length; i++) {
      soma += arr[i];
    };
    return numberToReal(soma);
  };

  function DespesaFixa() {
    var filter = Gestao.filter(e => (e.Tipo === 'Despesa Fixa' && FormataDataMesAno2(e.Data) === FormataDataMesAno1()));
    var filterD = Gestao.filter(e => (e.Tipo === 'Despesa Fixa' && FormataDataMesAno2Dai(e.Data) === FormataDataMesAno1Dai()));
    var arr = DateSellDay !== '' ? filterD.map(e => (LimpaCaracteresParseFloat(e.Valor))) : filter.map(e => (LimpaCaracteresParseFloat(e.Valor)));
    var soma = 0;
    for (var i = 0; i < arr.length; i++) {
      soma += arr[i];
    };
    return numberToReal(soma);
  };

  function TotalLiquido() {
    var filter1 = Gestao.filter(e => (e.Tipo === 'Receita' && FormataDataMesAno2(e.Data) === FormataDataMesAno1()));
    var filter1D = Gestao.filter(e => (e.Tipo === 'Receita' && FormataDataMesAno2Dai(e.Data) === FormataDataMesAno1Dai()));

    var arr1 = DateSellDay !== '' ? filter1D.map(e => (LimpaCaracteresParseFloat(e.Valor))) : filter1.map(e => (LimpaCaracteresParseFloat(e.Valor)));
    var soma1 = 0;
    for (var i = 0; i < arr1.length; i++) {
      soma1 += arr1[i];
    };

    var filter2 = Gestao.filter(e => (e.Tipo === 'Despesa' && FormataDataMesAno2(e.Data) === FormataDataMesAno1()));
    var filter2D = Gestao.filter(e => (e.Tipo === 'Despesa' && FormataDataMesAno2Dai(e.Data) === FormataDataMesAno1Dai()));
    var arr2 = DateSellDay !== '' ? filter2D.map(e => (LimpaCaracteresParseFloat(e.Valor))) : filter2.map(e => (LimpaCaracteresParseFloat(e.Valor)));
    var soma2 = 0;
    for (var i = 0; i < arr2.length; i++) {
      soma2 += arr2[i];
    };

    var filter3 = Gestao.filter(e => (e.Tipo === 'Despesa Fixa' && FormataDataMesAno2(e.Data) === FormataDataMesAno1()));
    var filter3D = Gestao.filter(e => (e.Tipo === 'Despesa Fixa' && FormataDataMesAno2Dai(e.Data) === FormataDataMesAno1Dai()));
    var arr3 = DateSellDay !== '' ? filter3D.map(e => (LimpaCaracteresParseFloat(e.Valor))) : filter3.map(e => (LimpaCaracteresParseFloat(e.Valor)))
    var soma3 = 0;
    for (var i = 0; i < arr3.length; i++) {
      soma3 += arr3[i];
    };

    const total = soma1 - (soma2 + soma3);

    if (total) {
      return { total: numberToReal(total), formt: total };
    } else {
      return { total: numberToReal(0), formt: 0 };
    }
  };

  //#region FiltrarCortesia
  function FiltrarCortesia() {

    const res1 = Faturas.filter(e => {
      if (e.Cortesia === 'Sim' && FormataDataMesAno2(e.Data) === FormataDataMesAno1()) {
        return e
      };
    });

    const res1D = Faturas.filter(e => {
      if (e.Cortesia === 'Sim' && FormataDataMesAno2Dai(e.Data) === FormataDataMesAno1Dai()) {
        return e
      };
    });

    const Valor1 = DateSellDay !== '' ? res1D.map(e => (LimpaCaracteresParseFloat(e.Procedimento))) : res1.map(e => (LimpaCaracteresParseFloat(e.Procedimento)));

    var sum1 = 0;
    for (var i = 0; i < Valor1.length; i++) {
      sum1 += Valor1[i];
    };

    const Result = parseFloat(sum1);
    return Result;
  };
  //#endregion

  //#region FiltrarFaltas
  function FiltrarFaltas() {

    const res1 = Faturas.filter(e => {
      if (e.Status_comp === 'Faltou' && FormataDataMesAno2(e.Data) === FormataDataMesAno1()) {
        return e
      };
    });

    const res1D = Faturas.filter(e => {
      if (e.Status_comp === 'Faltou' && FormataDataMesAno2Dai(e.Data) === FormataDataMesAno1Dai()) {
        return e
      };
    });

    const Valor1 = DateSellDay !== '' ? res1D.map(e => (LimpaCaracteresParseFloat(e.Procedimento))) : res1.map(e => (LimpaCaracteresParseFloat(e.Procedimento)));

    var sum1 = 0;
    for (var i = 0; i < Valor1.length; i++) {
      sum1 += Valor1[i];
    };

    const Result = parseFloat(sum1);
    return Result;
  };
  //#endregion

  //#region Limpar
  function Limpar() {
    setId('');
    setTitulo('');
    setDescricao('');
    setValor('');
    setTipo('');
    setFaturaCli('');
    setData(moment(new Date()).tz("America/Sao_Paulo").format('DD/MM/YYYY'));
  };
  //#endregion


  async function Salvar() {
    const Usuario = await localStorage.getItem('Id');
    axios({
      method: 'post',
      url: Config.Url + 'NovoGestao',
      data: {
        Titulo: Titulo,
        Descricao: Descricao,
        Tipo: Tipo,
        Valor: Valor,
        Data: moment(Data).tz("America/Sao_Paulo").format('DD/MM/YYYY'),
        Usuario: Usuario,
        Fatura: '',
        Paciente: '',
      }
    }).then(res => {
      if (res.data.sucesso === true) {
        Listar();
        setNovo(false)
        Limpar();
        message.success('Fatura salva com sucesso');
      } else {
        Listar();
        message.error(res.data.mensagem);
      };
    });
  };

  //#region UpdateGestao
  async function UpdateGestao() {
    axios({
      method: 'put',
      url: Config.Url + 'EditarGestao',
      data: {
        Id: Id,
        Titulo: Titulo,
        Descricao: Descricao,
        Tipo: Tipo,
        Valor: Valor,
        Data: moment(Data).tz("America/Sao_Paulo").format('DD/MM/YYYY')
      }
    }).then(res => {
      if (res.data.sucesso === true) {
        Listar();
        Limpar();
        setNovo(false)
        message.success('Fatura editada com sucesso');
      } else {
        Listar();
        message.error('Erro ao editar fatura');
      };
    });

  };
  //#endregion

  async function SalvarFixaInsert(model) {
    const Usuario = await localStorage.getItem('Id');
    axios({
      method: 'post',
      url: Config.Url + 'NovoGestao',
      data: {
        Titulo: model.Titulo,
        Descricao: '',
        Tipo: 'Despesa Fixa',
        Valor: model.Valor,
        Data: moment(new Date()).tz("America/Sao_Paulo").format('DD/MM/YYYY'),
        Usuario: Usuario,
        Fatura: '',
        Paciente: '',
      }
    }).then(res => {
      if (res.data.sucesso === true) {
        Listar();
        Limpar();
        message.success('Fatura salva com sucesso');
      } else {
        Listar();
        message.error(res.data.mensagem);
      };
    });

  };

  async function SalvarFixa() {
    const Usuario = await localStorage.getItem('Id');
    axios({
      method: 'post',
      url: Config.Url + 'NovoGestaoFixa',
      data: {
        Titulo: Titulo,
        Valor: Valor,
        Usuario: Usuario
      }
    }).then(res => {
      if (res.data.sucesso === true) {
        ListarFixa();
        Limpar();
        setADDNovoFixa(false)
        message.success('Fatura salva com sucesso');
      } else {
        ListarFixa();
        message.error(res.data.mensagem);
      };
    });
  };



  async function Delete(model) {
    axios({
      method: 'delete',
      url: Config.Url + 'DeletarGestao',
      data: {
        Id: model.Id
      }
    }).then(res => {
      if (res.data.sucesso === true) {
        Listar();
        Limpar();
        api.destroy(model.Id)
        message.success('Fatura excluída com sucesso');
      } else {
        Listar();
        Limpar();
        api.destroy(model.Id)
        message.error('Falha ao excluir');
      };
    });
  };
  const [api, contextHolder] = notification.useNotification();

  function MensagemDeleteFatura(model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => Delete(model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja excluir este registro ?',
      btn,
      key: model.Id
    });
  }

  async function DeleteFixa(model) {
    axios({
      method: 'delete',
      url: Config.Url + 'DeletarGestaoFixa',
      data: {
        Id: model.Id
      }
    }).then(res => {
      if (res.data.sucesso === true) {
        ListarFixa();
        api.destroy(model.Id)
        Limpar();
        message.success('Fatura excluída com sucesso');
      } else {
        ListarFixa();
        api.destroy(model.Id)
        message.error('Falha ao excluir');
        Limpar();
      };
    });
  };

  function MensagemDeleteFaturaFixa(model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => DeleteFixa(model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja excluir este registro ?',
      btn,
      key: model.Id
    });
  }

  const columns = [
    {
      title: 'TÍTULO',
      dataIndex: 'Titulo',
      key: 'Titulo',
    },
    {
      title: 'VALOR',
      dataIndex: 'Valor',
      key: 'Valor',
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => SalvarFixaInsert(key)} title='Aplicar' type="primary" shape="circle" icon={<SendOutlined />} />
          <Button onClick={() => MensagemDeleteFaturaFixa(key)} title='Excluir' type="primary" danger shape="circle" icon={<DeleteOutlined />} />
        </Space>
    },
  ];

  return (
    <div className='ContainerHome'>
      {contextHolder}
      <div className='ContainerTableNav2'>
        <div style={{ position: 'fixed', left: 0, marginLeft: 35 }}>
          <Space>
            <Button onClick={() => setNovo(true)} type="primary" >ADICIONAR</Button>
            <Button danger onClick={() => setNovoFixa(true)} type="primary" >DESPESAS FIXAS</Button>
            <Button type="primary" danger onClick={() => setPdf(true)}>PDF</Button>
          </Space>
        </div>
        <h2 style={{ color: '#fff' }}>{FormatarDataMes(DateSellDay !== '' ? DateSellDay : DateSell).toUpperCase()}</h2>
        <div style={{ width: '100%', height: 80, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

          <div style={{ width: '74%', borderRadius: 10, height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
            <div style={{ width: '50%', paddingInline: 20, display: 'flex', flexDirection: 'column' }}>

              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <text style={{ color: '#fff' }}>Receita:</text>
                <text style={{ color: '#b1ff00', fontSize: 17 }}>{Receita()}</text>
              </div>

              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <text style={{ color: '#fff' }}>Despesas:</text>
                <text style={{ color: '#f00', fontSize: 17 }}>{Despesa()}</text>
              </div>

              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <text style={{ color: '#fff' }}>Despesas Fixas:</text>
                <text style={{ color: '#f00', fontSize: 17 }}>{DespesaFixa()}</text>
              </div>

            </div>
            <div style={{ width: '1%', height: '90%', borderRadius: 50, backgroundColor: '#fff' }} />
            <div style={{ width: '50%', paddingInline: 20, display: 'flex', flexDirection: 'column' }}>

              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Button onClick={() => setReceber(true)} style={{ height: 30 }}>
                  <text style={{ color: '#fff' }}>A Receber:</text>
                </Button>
                <text style={{ color: '#f00', fontSize: 17 }}>{numberToReal(FiltrarDebitos())}</text>
              </div>

              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <text style={{ color: '#fff' }}>Cortesias:</text>
                <text style={{ color: '#f00', fontSize: 17 }}>{numberToReal(FiltrarCortesia())}</text>
              </div>

              <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <text style={{ color: '#fff' }}>Faltas:</text>
                <text style={{ color: '#f00', fontSize: 17 }}>{numberToReal(FiltrarFaltas())}</text>
              </div>

            </div>
          </div>

          <div style={{ width: '25%', marginTop: -10, minHeight: 115, borderRadius: 10, backgroundColor: '#fff', padding: 10, height: '100%', alignItems: 'center', justifyContent: 'center', paddingInline: 20, display: 'flex', flexDirection: 'column' }}>
            <text style={{ fontWeight: 'bold' }}>FILTROS</text>
            <Space style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
              <DatePicker onChange={(e) => setDateSell(e !== null ? moment(e.$d).tz("America/Sao_Paulo").format('DD/MM/YYYY') : moment().tz("America/Sao_Paulo").format('DD/MM/YYYY'))} placeholder='Selecione o mês' format={'MM/YYYY'} style={{ width: 200 }} picker="month" size='large' />
              <DatePicker onChange={(e) => setDateSellDay(e !== null ? moment(e.$d).tz("America/Sao_Paulo").format('DD/MM/YYYY') : '')} showToday={false} placeholder='Selecione o dia' format={'DD/MM/YYYY'} style={{ width: 200 }} size='large' />
            </Space>
          </div>

        </div>
        <div style={{ width: '100%', paddingInline: 23 }}>
          <text style={{ color: TotalLiquido()['formt'] > 0 ? '#b1ff00' : '#f00', }}>Total líquido: {TotalLiquido()['total']}</text>
        </div>
      </div>

      <div className='ContainerTable2'>
        {RetornoGestao.map((d, ind) => (
          <div key={ind} style={{ width: '100%', marginBottom: 20, minHeight: 120 }}>
            <div style={{ width: '100%', height: 60, display: 'flex', marginBottom: 40, flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <h2 style={{ color: '#545454', }}>{FormatarDataMes(d.Data).toUpperCase()}</h2>
              <div style={{ width: 10, minHeight: 50, borderRadius: 50, backgroundColor: '#545454' }} />
            </div>
            {d.Json.map((e, index) => (
              <div key={index} style={{ width: '100%', height: 80, marginBottom: 10, display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingInline: 5, borderRadius: 5, backgroundColor: '#a7a7a7' }}>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div style={{ width: 15, height: 55, backgroundColor: e.Fatura !== '' ? Config.Azul : e.Tipo === 'Receita' ? Config.Verde : e.Tipo === 'Despesa Fixa' ? Config.Tercery : Config.Vermelho, borderRadius: 55 }} />
                  <div style={{ height: 80, paddingLeft: 5, display: 'flex', flexDirection: 'column' }}>
                    <text style={{ color: Config.COR2, fontSize: 18, fontWeight: 'bold' }}>{e.Titulo}</text>
                    <text style={{ color: '#fff', fontSize: 13 }}>{e.Fatura !== '' ? TrazNome(e.Paciente) : e.Tipo}</text>
                    <text style={{ color: '#fff', fontSize: 13 }}>Valor pago: {e.Valor}</text>
                    <text style={{ color: '#fff', fontSize: 13 }}>Data: {e.Data}</text>
                  </div>
                </div>

                <div style={{ marginRight: 5 }} >
                  <Space >
                    <Button type="primary" onClick={() => AbrirRegistro(e)} shape="circle" icon={<EyeOutlined />} />
                    {e.Fatura === '' && <Button onClick={() => MensagemDeleteFatura(e)} type="primary" shape="circle" danger icon={<DeleteOutlined />} />}
                  </Space>
                </div>

              </div>
            ))}
          </div>
        ))}
      </div>

      <Drawer
        title={"Despesas Fixas"}
        closable={false}
        width={560}
        placement="right"
        open={NovoFixa}
        extra={
          <Space>
            <Button onClick={() => setNovoFixa(false)}>Fechar</Button>
            <Button type="primary" onClick={() => setADDNovoFixa(true)} >Adicionar</Button>
          </Space>
        }>
        <Table dataSource={GestaoFixa} max columns={columns} />
      </Drawer>

      <Drawer
        title={"A Receber"}
        closable={false}
        width={560}
        placement="right"
        open={Receber}
        extra={
          <Space>
            <Button onClick={() => setReceber(false)}>Fechar</Button>
          </Space>
        }>
        {AReceber.map((e, index) => (
          <div key={index} style={{ width: '100%', height: 30, display:'flex', marginBottom: 10,  flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', backgroundColor: Config.CinzaEscuro, borderRadius: 5 }}>
            <text style={{ color: Config.Branco, fontWeight: 'bold', marginLeft:10 }}>{e.Paciente} </text>
            <text style={{ color: Config.Branco, fontWeight: 'bold', marginRight:10 }}>{numberToReal(e.Valor)} </text>
          </div>
        ))}
      </Drawer>

      <Drawer
        title={"Nova gestão fixa"}
        closable={false}
        width={480}
        placement="right"
        open={ADDNovoFixa}
        extra={
          <Space>
            <Button onClick={() => [setADDNovoFixa(false), Limpar()]}>Fechar</Button>
            {FaturaCli === '' && <Button type="primary" onClick={() => SalvarFixa()} >Salvar</Button>}
          </Space>
        }>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ flexDirection: 'column', display: 'flex', width: '48%' }}>
            <label style={{ marginLeft: 5 }}>Título</label>
            <Input value={Titulo} onChange={(e) => setTitulo(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Título" />
          </div>
          <div style={{ flexDirection: 'column', display: 'flex', width: '48%' }}>
            <label style={{ marginLeft: 5 }}>Valor</label>
            <Input value={Valor} onChange={(e) => setValor(formatarMoeda(e.target.value))} size={'large'} style={{ width: '100%' }} placeholder="R$0,00" />
          </div>
        </div>

      </Drawer>

      <Drawer
        title={Id !== '' ? "Editar gestão" : "Nova gestão"}
        closable={false}
        width={480}
        placement="right"
        open={Novo}
        extra={
          <Space>
            <Button onClick={() => [setNovo(!Novo), Limpar()]}>Fechar</Button>
            {FaturaCli === '' && <Button type="primary" onClick={() => Id !== '' ? UpdateGestao() : Salvar()} >Salvar</Button>}
          </Space>
        }>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ flexDirection: 'column', display: 'flex', width: '48%' }}>
            <label style={{ marginLeft: 5 }}>Tipo</label>
            <Select value={Tipo} onSelect={(e) => setTipo(e)} size={'large'} style={{ width: '100%' }} placeholder="Tipo" >
              <options value="">Tipo</options>
              <options value="Receita">Receita</options>
              <options value="Despesa">Despesa</options>
            </Select>
          </div>
          <div style={{ flexDirection: 'column', display: 'flex', width: '48%' }}>
            <label style={{ marginLeft: 5 }}>Título</label>
            <Input value={Titulo} onChange={(e) => setTitulo(e.target.value)} size={'large'} style={{ width: '100%', color: 'black', backgroundColor: 'white' }} placeholder="Título" />
          </div>
        </div>

        <div style={{ display: 'flex', width: '102%', justifyContent: 'space-between', marginBottom: 10 }}>
          <div style={{ flexDirection: 'column', display: 'flex', width: '48%' }}>
            <label style={{ marginLeft: 5 }}>Data</label>
            <Input value={Data} onChange={(e) => setData(e.target.value)} size={'large'} style={{ width: '100%' }} type="date" />
          </div>
          <div style={{ flexDirection: 'column', display: 'flex', width: '48%' }}>
            <label style={{ marginLeft: 5 }}>Valor</label>
            <Input value={Valor} onChange={(e) => setValor(formatarMoeda(e.target.value))} size={'large'} style={{ width: '100%' }} placeholder="R$0,00" />
          </div>
        </div>

        <div style={{ flexDirection: 'column', display: 'flex', width: '102%' }}>
          <label style={{ marginLeft: 5 }}>Observação</label>
          <Input.TextArea value={Descricao} onChange={(e) => setDescricao(e.target.value)} size={'large'} autoSize={{ minRows: 3, maxRows: 10 }} style={{ width: '102%', height: 200 }} placeholder="Obs" />
        </div>

      </Drawer>


      <Modal width={1000} open={Pdf} onCancel={() => setPdf(false)} footer={[]} closable={false}>
        <div style={{ height: 800 }}>
          <PDFViewer style={{ width: '100%', height: '100%' }}>
            <Document>
              <Page size="A4" style={styles.page}>
                <div style={{ width: '100%', padding: 25, height: 180, backgroundColor: '#3c5270', alignItems: 'center' }}>
                  <Text style={{ color: '#fff', fontWeight: 'bold' }}>{localStorage.getItem('Empresa')}</Text>
                  <View style={styles.section}>
                    <Text style={{ fontSize: 15, marginBottom: 10, color: '#fff' }}>Profissional: {localStorage.getItem('Nome')}</Text>

                    <Text style={{ fontSize: 15, marginBottom: 10, color: '#fff' }}>Telefone: {localStorage.getItem('Telefone')}</Text>

                    <Text style={{ fontSize: 15, marginBottom: 10, color: '#fff' }}>Cidade: {localStorage.getItem('Cidade')}</Text>

                    <Text style={{ fontSize: 15, marginBottom: 10, color: '#fff' }}>Gerado na data: {moment().tz("America/Sao_Paulo").format('DD/MM/YYYY HH:mm:ss')}</Text>
                  </View>
                </div>

                <div style={{ backgroundColor: Config.Primary, height: 150, width: '100%', alignItems: 'center', flexDirection: 'column', padding: 10 }}>
                  <Text style={{ color: '#fff' }}>{FormatarDataMes(DateSellDay !== '' ? DateSellDay : DateSell).toUpperCase()}</Text>
                  <div style={{ width: '100%', height: 80, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

                    <div style={{ width: '100%', borderRadius: 10, height: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                      <div style={{ width: '50%', padding: 10, display: 'flex', flexDirection: 'column' }}>

                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <Text style={{ color: '#fff' }}>Receita:</Text>
                          <Text style={{ color: '#b1ff00', fontSize: 17 }}>{Receita()}</Text>
                        </div>

                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <Text style={{ color: '#fff' }}>Despesas:</Text>
                          <Text style={{ color: '#f00', fontSize: 17 }}>{Despesa()}</Text>
                        </div>

                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <Text style={{ color: '#fff' }}>Despesas Fixas:</Text>
                          <Text style={{ color: '#f00', fontSize: 17 }}>{DespesaFixa()}</Text>
                        </div>

                      </div>
                      <div style={{ width: '1%', height: '90%', borderRadius: 50, backgroundColor: '#fff' }} />
                      <div style={{ width: '50%', padding: 10, display: 'flex', flexDirection: 'column' }}>

                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <Text style={{ color: '#fff' }}>A Receber:</Text>
                          <Text style={{ color: '#f00', fontSize: 17 }}>{numberToReal(FiltrarDebitos())}</Text>
                        </div>

                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <Text style={{ color: '#fff' }}>Cortesias:</Text>
                          <Text style={{ color: '#f00', fontSize: 17 }}>{numberToReal(FiltrarCortesia())}</Text>
                        </div>

                        <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <Text style={{ color: '#fff' }}>Faltas:</Text>
                          <Text style={{ color: '#f00', fontSize: 17 }}>{numberToReal(FiltrarFaltas())}</Text>
                        </div>

                      </div>
                    </div>

                  </div>
                  <div style={{ width: '100%', paddingInline: 23 }}>
                    <Text style={{ color: TotalLiquido()['formt'] > 0 ? '#b1ff00' : '#f00', }}>Total líquido: {TotalLiquido()['total']}</Text>
                  </div>
                </div>

                <View style={styles.table}>
                  <View style={styles.row}>
                    <div style={{ width: '25%', height: 30, backgroundColor: '#545454', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontSize: 12, color: '#fff' }}>Tipo</Text>
                    </div>
                    <div style={{ width: '25%', height: 30, backgroundColor: '#545454', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontSize: 12, color: '#fff' }}>Título</Text>
                    </div>
                    <div style={{ width: '25%', height: 30, backgroundColor: '#545454', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontSize: 12, color: '#fff' }}>Valor Pago</Text>
                    </div>
                    <div style={{ width: '25%', height: 30, backgroundColor: '#545454', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <Text style={{ fontSize: 12, color: '#fff' }}>Data</Text>
                    </div>

                  </View>

                  {Gestaoes.filter(e => DateSellDay !== '' ? FormataDataMesAno2Dai(e.Data) === FormataDataMesAno1Dai() : FormataDataMesAno2(e.Data) === FormataDataMesAno1()).map((e, index) => (
                    <View style={styles.rows}>
                      <div style={{ width: '25%', height: 30, backgroundColor: e.Fatura !== '' ? Config.Azul : e.Tipo === 'Receita' ? Config.Verde : e.Tipo === 'Despesa Fixa' ? Config.Tercery : Config.Vermelho, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: 10, color: e.Tipo === 'Despesa Fixa' ? '#000':'#fff' }}>{e.Tipo}</Text>
                      </div>
                      <div style={{ width: '25%', height: 30, backgroundColor: '#eee', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: 10, color: '#545454' }}>{e.Titulo}</Text>
                      </div>
                      <div style={{ width: '25%', height: 30, backgroundColor: '#eee', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: 10, color: '#545454' }}>{e.Valor}</Text>
                      </div>
                      <div style={{ width: '25%', height: 30, backgroundColor: '#eee', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Text style={{ fontSize: 10, color: '#545454' }}>{e.Data}</Text>
                      </div>
                    </View>
                  ))}

                </View>

              </Page>
            </Document>
          </PDFViewer>
        </div>
      </Modal>

    </div>
  );
};

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff'
  },
  section: {
    marginTop: 25,
    width: '100%',
  },
  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTop: '1px solid #EEE',
    paddingBottom: 8,
  },
  rows: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTop: '1px solid #EEE',
    marginBottom: 1

  }
});

export default Gestao;
