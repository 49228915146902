import React from 'react';
import { PDFViewer } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

// Create styles
const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: '#fff'
    },
    section: {
        marginTop: 25,
        width: '100%',
    },
    table: {
        width: '100%',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderTop: '1px solid #EEE',
        paddingBottom: 8,
    },
    rows: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        borderTop: '1px solid #EEE',
        marginBottom:1

    }
});



// Create Document Component
const MyDocument = () => (
    <Document>
        <Page size="A4" style={styles.page}>
            <div style={{ width: '100%', padding: 25, height: 180, backgroundColor: '#3c5270', alignItems: 'center' }}>
                <Text style={{ color: '#fff', fontWeight:'bold' }}>ACUPUNTURA MAIS</Text>
                <View style={styles.section}>
                    <Text style={{ fontSize: 15, marginBottom: 10, color:'#fff' }}>Profissional: Wander Pimenta Filho</Text>

                    <Text style={{ fontSize: 15, marginBottom: 10, color:'#fff' }}>Telefone: 359841651651</Text>

                    <Text style={{ fontSize: 15, marginBottom: 10 , color:'#fff'}}>Cidade: São Sebastião do Paraiso</Text>

                    <Text style={{ fontSize: 15, marginBottom: 10, color:'#fff' }}>Gerado na data: 23/04/2023 14:58:12</Text>
                </View>
            </div>

            <View style={styles.table}>
                <View style={styles.row}>
                    <div style={{width: '20%', height: 30, backgroundColor: '#545454', display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Text style={{ fontSize: 12, color:'#fff' }}>Name</Text>
                    </div>
                    <div style={{width: '20%', height: 30, backgroundColor: '#545454', display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Text style={{ fontSize: 12, color:'#fff' }}>Nascimento</Text>
                    </div>
                    <div style={{width: '20%', height: 30, backgroundColor: '#545454', display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Text style={{ fontSize: 12, color:'#fff' }}>Cpf</Text>
                    </div>
                    <div style={{width: '20%', height: 30, backgroundColor: '#545454', display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Text style={{ fontSize: 12, color:'#fff' }}>Telefone</Text>
                    </div>
                    <div style={{width: '20%', height: 30, backgroundColor: '#545454', display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <Text style={{ fontSize: 12, color:'#fff' }}>Sexo</Text>
                    </div>
                </View>


            </View>

        </Page>
    </Document>
);

const App = () => (
    <div className='Container'>
        <PDFViewer className='Container'>
            <MyDocument />
        </PDFViewer>
    </div>
);


export default App