import { useState } from "react";
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import Config from './../../Configuracoes/config';
import axios from 'axios';

function Funcoes() {
    const [Usuario, setUsuario] = useState("");
    const [Senha, setSenha] = useState("");
    const [Load, setLoad] = useState(false);
    const [Security, setSecurity] = useState(false);

    function Munsagens(params) {
        if (params === 'Sucesso') {
            message.open({
                type: 'success',
                content: 'Login efetuado com sucesso.',
            });
            window.location.reload(false);
        } else if (params === 'Senha') {
            message.open({
                type: 'info',
                content: 'Senha incorreta.',
            });
        } else {
            message.open({
                type: 'error',
                content: params,
            });
        };
    };

    async function Logar() {
        setLoad(true);
        if (Usuario === "") {
            message.open({
                type: 'info',
                content: 'Informe um usuário.',
            });
            setLoad(false);
            return;
        }
        if (Senha === "") {
            message.open({
                type: 'info',
                content: 'Informe sua Senha.',
            });
            setLoad(false);
            return;
        }

        await axios({
            method: 'post',
            url: Config.Url + 'Login',
            data: { Usuario, Senha }

        }).then(e => {
            const Data = e.data;

            if (Data.sucesso) {

                const Id = Data.result['Id'];
                const Nome = Data.result['Nome'];
                const Usuario = Data.result['Usuario'];
                const Telefone = Data.result['Telefone'];
                const Cidade = Data.result['Cidade'];
                const Empresa = Data.result['Empresa'];
                const Stripe = Data.stripe;
                const Pais = Data.result['Pais'];;
                const Token = Data.token;
                const Start = Data.start;
                const End = Data.end;
                if (Stripe) {
                    const Id_cliente = Data.result['Id_cliente'];
                    const Codigo_assinatura = Data.result['Codigo_assinatura'];
                    localStorage.setItem('Codigo_assinatura', Codigo_assinatura);
                    localStorage.setItem('Id_cliente', Id_cliente);
                };
                localStorage.setItem('Stripe', Stripe ? "OPEN" : "CLOSE");
                localStorage.setItem('Id', Id);
                localStorage.setItem('Nome', Nome);
                localStorage.setItem('Telefone', Telefone);
                localStorage.setItem('Cidade', Cidade);
                localStorage.setItem('Empresa', Empresa);
                localStorage.setItem('Usuario', Usuario);
                localStorage.setItem('Senha', Senha);
                localStorage.setItem('Pais', Pais);
                localStorage.setItem('Token', Token);
                localStorage.setItem('Start', Start);
                localStorage.setItem('End', End);

                setTimeout(() => {
                    setLoad(false);
                    Munsagens("Sucesso");
                }, 2500);
            } else {
                setLoad(false);
                Munsagens(Data.mensagem);
            }
        })

    };

    return { Load, Logar, Usuario, setUsuario, Senha, setSenha, Security, setSecurity };
};

export default Funcoes;