import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from "../Pages/Login/indes";
import Lottie from 'react-lottie';
import Block from './../Images/block.json';
import Construtor from './Construtor';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import Config from '../Configuracoes/config';
import axios from 'axios';
const moment = require('moment-timezone');

function ContrutorLogin() {
    const [Autorizado, setAutorizado] = useState(null);
    const [Token, setToken] = useState('');
    const navigate = useNavigate();
// @SenhaPro1606810

    async function VerificaAssinatura(codigo_assinatura) {
        await axios({
            method: 'post',
            url: Config.Url + 'ListarAssinar',
            data: { assinatura: codigo_assinatura }
        }).then(e => {
            const Status = e.data.result.status;
            if (Status === 'trialing' || Status === 'active') {
                navigate('dashboard/agenda');
                message.success('Assinatura verificada.')
            } else {
                localStorage.clear();
                message.error('Falha ao verificar assinatura. Verifique dentro do aplicativo se sua assinatura está ativa.')
            }
        });

    };

    async function VerificaAssinaturaCobre() {
        const UrlAssinatura = 'https://cobrefacil.acupunturapro.com/';
        try {
            const Id = await localStorage.getItem('Id');
            await axios({
                method: 'get',
                url: UrlAssinatura + `Assinaturas/${Id}/getassinatura`
            }).then(e => e.data)
                .then(e => {
                    if (e.success) {
                        const Data = e.data;
                        const Clinete = e.Clinete;
                        const ClienteLoacl = e.ClienteLoacl;
                        localStorage.setItem('CustumerId', ClienteLoacl[0].paguefacilId);
                        if (Data.id) {
                            localStorage.setItem('IdAssinatura', Data.id);
                            localStorage.setItem('AssinatuaStatus', Data.status);
                        };
                        const Assinaturaiof = e.off.filter(e => {
                            if (moment(e.first_due_date) > moment()) {
                                return e;
                            };
                        });
                        if (Assinaturaiof.length > 0) {
                            navigate('dashboard/agenda');
                            message.success('Assinatura verificada.')
                            return;
                        }else{
                            switch (Data.status) {
                                case "actived":
                                    navigate('dashboard/agenda');
                                    message.success('Assinatura verificada.')
                                    break;
                                default:
                                    localStorage.clear();
                                    message.error('Falha ao verificar assinatura. Verifique dentro do aplicativo se sua assinatura está ativa.')
                                    break;
                            };
                        }
                    } else {
                        localStorage.clear();
                        message.error('Falha ao verificar assinatura. Verifique dentro do aplicativo se sua assinatura está ativa.')
                    };
                });
        } catch (error) {
            localStorage.clear();
            message.error('Falha ao verificar assinatura. Verifique dentro do aplicativo se sua assinatura está ativa.')
        };
    };

    async function VereficaLogin() {
        const End = await localStorage.getItem('End');
        const TokenUser = await localStorage.getItem('Token');
        const Codigo_assinatura = await localStorage.getItem('Codigo_assinatura');
        const Id = await localStorage.getItem('Id');

        if (End) {
            const Agora = moment().tz("America/Sao_Paulo").format();
            const Fim = moment(End).tz("America/Sao_Paulo").format();

            if (Agora > Fim) {
                setToken('');
                setTimeout(() => {
                    setAutorizado(true)
                }, 2000);
            } else {
                message.open({
                    type: 'success',
                    content: 'Login efetuado com sucesso.',
                });
                setAutorizado(true)
                setToken(TokenUser);
                const Stripe = await localStorage.getItem('Stripe');
                if (Stripe === "OPEN") {
                    VerificaAssinatura(Codigo_assinatura)
                } else {
                    VerificaAssinaturaCobre(Id)
                };

            }

        } else {
            setToken('');
            setTimeout(() => {
                setAutorizado(true)
            }, 2000);
        };
    };

    useEffect(() => {
        VereficaLogin();
    }, []);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Block,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    if (Autorizado) {
        return (
            <Routes>
                <Route path='/' element={<Login />} />
                <Route path='/index.html' element={<Login />} />
                {Token !== '' && <Route path='/dashboard/*' element={<Construtor />} />}
                {Token !== '' && <Route path='/index.html/dashboard/*' element={<Construtor />} />}
            </Routes>
        );
    } else {
        return (
            <div style={{ position: 'absolute', backgroundColor: "#1A2B52", width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
                <div style={{ backgroundColor: '#fff', borderRadius: 10, flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Lottie
                        options={defaultOptions}
                        height={400}
                        width={400}
                    />
                    <h1 style={{ position: 'absolute', marginTop: 300, color: "#1A2B52" }}>{Autorizado === null ? "Verificando" : Autorizado === null ? "Autorizado" : "Sem Autorização"}</h1>
                </div>
            </div>
        );
    };

};

export default ContrutorLogin;
