import { useState, useEffect } from 'react'
import Config from './../../Configuracoes/config';
import { Table, Button, Space, Input, Col, Row, Form, Drawer, Avatar, Image, message, notification, Modal } from 'antd';
import { EditOutlined, CheckOutlined, EyeInvisibleOutlined, FilePptOutlined, EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import './prontuarios.css';
import { PDFViewer } from '@react-pdf/renderer';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
const { Search } = Input;
const moment = require('moment-timezone');

function Prontuarios() {

  const [Buscar, setBuscar] = useState('');
  const [Prontuarios, setProntuarios] = useState([]);
  const [Pacientes, setPacientes] = useState([]);
  const [Prescricoes, setPrescricoes] = useState([]);
  const [Open, setOpen] = useState(false);
  const onSearch = (value) => setBuscar(value);
  const [abre, setAbre] = useState('');
  const [edit, setedit] = useState('');
  const [AbrirPrescricoes, setAbrirPrescricoes] = useState(false);
  const [Pdf, setPdf] = useState(false);

  function selecionar(params) {
    if (abre === '') {
      setAbre(params);
    } else if (abre === params) {
      setAbre('');
    } else {
      setAbre(params);
    };
  };
  const [IdPrescricao, setIdPrescricao] = useState('');
  const [Foto, setFoto] = useState('');
  const [Nome, setNome] = useState('');
  const [Cpf, setCpf] = useState('');
  const [Sexo, setSexo] = useState('');
  const [Nascimento, setNascimento] = useState('');
  const [EstadoCivil, setEstadoCivil] = useState('');
  const [Profissao, setProfissao] = useState('');
  const [Idade, setIdade] = useState('');

  const [Id, setId] = useState('');
  const [queixa_principal, setqueixa_principal] = useState('');
  const [quaixa_secundaria, setquaixa_secundaria] = useState('');
  const [historico, sethistorico] = useState('');
  const [emocional, setemocional] = useState('');
  const [calor_frio, setcalor_frio] = useState('');
  const [traspiracao, settraspiracao] = useState('');
  const [dor, setdor] = useState('');
  const [cabeca, setcabeca] = useState('');
  const [sist_musc_esq, setsist_musc_esq] = useState('');
  const [fezas_urina, setfezas_urina] = useState('');
  const [sono, setsono] = useState('');
  const [alimentos_sabor, setalimentos_sabor] = useState('');
  const [sede_bebidas, setsede_bebidas] = useState('');
  const [audição_tinidos, setaudição_tinidos] = useState('');
  const [aparelho_genitourinario, setaparelho_genitourinario] = useState('');
  const [mestruacao, setmestruacao] = useState('');
  const [lingua_cor, setlingua_cor] = useState('');
  const [lingua_forma, setlingua_forma] = useState('');
  const [lingua_saburra, setlingua_saburra] = useState('');
  const [pulso, setpulso] = useState('');
  const [es_r, setes_r] = useState('');
  const [es_f, setes_f] = useState('');
  const [es_c, setes_c] = useState('');
  const [es_b, setes_b] = useState('');
  const [es_vb, setes_vb] = useState('');
  const [es_id, setes_id] = useState('');
  const [dr_p, setdr_p] = useState('');
  const [dr_bp, setdr_bp] = useState('');
  const [dr_cs, setdr_cs] = useState('');
  const [dr_ig, setdr_ig] = useState('');
  const [dr_e, setdr_e] = useState('');
  const [dr_ta, setdr_ta] = useState('');
  const [_8_exterior_interior, set_8_exterior_interior] = useState('');
  const [_8_penitude_vazio, set_8_penitude_vazio] = useState('');
  const [_8_calor_frio, set_8_calor_frio] = useState('');
  const [_8_yin_yang, set_8_yin_yang] = useState('');
  const [sub_qi, setsub_qi] = useState('');
  const [sub_xue, setsub_xue] = useState('');
  const [sub_jinye, setsub_jinye] = useState('');
  const [sub_jing, setsub_jing] = useState('');
  const [sub_shen, setsub_shen] = useState('');
  const [zang_rim, setzang_rim] = useState('');
  const [zang_figado, setzang_figado] = useState('');
  const [zang_coracao, setzang_coracao] = useState('');
  const [zang_baco, setzang_baco] = useState('');
  const [zang_pulmao, setzang_pulmao] = useState('');
  const [zang_visceras, setzang_visceras] = useState('');
  const [_5_elementos, set_5_elementos] = useState('');
  const [diag_energetico, setdiag_energetico] = useState('');
  const [proposta_terapeutica, setproposta_terapeutica] = useState('');
  const [prescricao_text, setprescricao_text] = useState('');

  const PrescricoesId = Prescricoes.filter(e => (e.Prontuario === Id));


  function CalculaIdade(dataNasc) {
    if (dataNasc) {
      var dataAtual = new Date();
      var anoAtual = dataAtual.getFullYear();
      var anoNascParts = dataNasc.split('/');
      var diaNasc = anoNascParts[0];
      var mesNasc = anoNascParts[1];
      var anoNasc = anoNascParts[2];
      var idade = anoAtual - anoNasc;
      var mesAtual = dataAtual.getMonth() + 1;
      if (mesAtual < mesNasc) {
        idade--;
      } else {
        if (mesAtual == mesNasc) {
          if (new Date().getDate() < diaNasc) {
            idade--;
          }
        }
      }
      return idade + ' Anos';
    } else {
      return "- -";
    };
  };

  function AbrirProntuario(Model) {

    setNome(Model.Nome);
    setFoto(Model.Foto);
    setCpf(Model.Cpf);
    setSexo(Model.Sexo);
    setNascimento(Model.Nascimento);
    setEstadoCivil(Model.Estado_civil);
    setProfissao(Model.Profissao);
    setIdade(CalculaIdade(Model.Nascimento));


    Prontuarios.filter(e => e.Paciente === Model.Id)
      .map(model => {
        console.log(model)
        setId(model.Id);
        setqueixa_principal(model.Queixa_principal);
        setquaixa_secundaria(model.Quaixa_secundaria);
        sethistorico(model.Historico);
        setemocional(model.Emocional);
        setcalor_frio(model.Calor_frio);
        settraspiracao(model.Traspiracao);
        setdor(model.Dor);
        setcabeca(model.Cabeca);
        setsist_musc_esq(model.Sist_musc_esq);
        setfezas_urina(model.Fezes_urina);
        setsono(model.Sono);
        setalimentos_sabor(model.Alimentos_sabor);
        setsede_bebidas(model.Sede_bebidas);
        setaudição_tinidos(model.Audição_tinidos);
        setaparelho_genitourinario(model.Aparelho_genitourinario);
        setmestruacao(model.Mestruacao);
        setlingua_cor(model.Lingua_cor);
        setlingua_forma(model.Lingua_forma);
        setlingua_saburra(model.Lingua_saburra);
        setpulso(model.Pulso);
        setes_r(model.Es_r);
        setes_f(model.Es_f);
        setes_c(model.Es_c);
        setes_b(model.Es_b);
        setes_vb(model.Es_vb);
        setes_id(model.Es_id);
        setdr_p(model.Dr_p);
        setdr_bp(model.Dr_bp);
        setdr_cs(model.Dr_cs);
        setdr_ig(model.Dr_ig);
        setdr_e(model.Dr_e);
        setdr_ta(model.Dr_ta);
        set_8_exterior_interior(model._8exterior_interior);
        set_8_penitude_vazio(model._8_penitude_vazio);
        set_8_calor_frio(model._8_calor_frio);
        set_8_yin_yang(model._8_yin_yang);
        setsub_qi(model.Sub_qi);
        setsub_xue(model.Sub_xue);
        setsub_jinye(model.Sub_jinye);
        setsub_jing(model.Sub_jing);
        setsub_shen(model.Sub_shen);
        setzang_rim(model.Zang_rim);
        setzang_figado(model.Zang_figado);
        setzang_coracao(model.Zang_coracao);
        setzang_baco(model.Zang_baco);
        setzang_pulmao(model.Zang_pulmao);
        setzang_visceras(model.Zang_visceras);
        set_5_elementos(model._5_elementos);
        setdiag_energetico(model.Diag_energetico);
        setproposta_terapeutica(model.Proposta_terapeutica);
        setOpen(true);
      })
  };

  async function Listar() {
    const Usuario = await localStorage.getItem('Id');
    axios({
      method: 'post',
      url: Config.Url + 'ListarProntuarios',
      data: {
        Usuario: Usuario
      }
    }).then(res => {
      if (res.data.sucesso === true) {
        setProntuarios(res.data.result)
      } else {
        setProntuarios([])
      };
    });
  };

  async function ListarPacientes() {
    const Usuario = await localStorage.getItem('Id');
    axios({
      method: 'post',
      url: Config.Url + 'ListarPacientesFiltro',
      data: {
        Usuario, Buscar: `%${Buscar}%`
      }
    }).then(res => {
      if (res.data.sucesso) {
        setPacientes(res.data.result);
      } else {
        setPacientes([]);
      };
    });
  };


  const columns = [
    {
      title: 'FOTO',
      dataIndex: 'Foto',
      key: 'Foto',
      render: (dataIndex,) =>
        <di>
          <Avatar size={60} src={<Image style={{ width: 60, height: 60, borderRadius: 60 }} src={dataIndex === '' ? require('./../../Images/semimagem.png') : Config.Url + 'Pacientes/' + dataIndex} />} />
        </di>
    },
    {
      title: 'NOME',
      dataIndex: 'Nome',
      key: 'Nome',
    },
    {
      title: 'NASCIMENTO',
      dataIndex: 'Nascimento',
      key: 'Nascimento',
    },
    {
      title: 'TELEFONE',
      dataIndex: 'Telefone',
      key: 'Telefone',
    },
    {
      title: 'SEXO',
      dataIndex: 'Sexo',
      key: 'Sexo',
    },
    {
      title: 'AÇÕES',
      dataIndex: 'Id',
      key: 'x',
      render: (dataIndex, key) =>
        <Space wrap>
          <Button onClick={() => [AbrirProntuario(key), setprescricao_text('')]} title='Editar' type="primary" shape="circle" icon={<EyeOutlined />} />
        </Space>
    },
  ];

  async function ListarPrescrcao() {
    const Usuario = await localStorage.getItem('Id');
    axios({
      method: 'post',
      url: Config.Url + 'ListarPrescricoes',
      data: {
        Usuario: Usuario
      }
    }).then(res => {
      if (res.data.sucesso === true) {
        setPrescricoes(res.data.result)
      } else {
        setPrescricoes([])
      };
    });
  };

  async function InsertPrescricao() {
    const Usuario = await localStorage.getItem('Id');
    axios({
      method: 'post',
      url: Config.Url + 'NovoPrescricoes',
      data: {
        Texto: moment(new Date()).tz("America/Sao_Paulo").format('DD/MM/YYYY') + " - " + prescricao_text,
        Prontuario: Id,
        Usuario: Usuario
      }
    }).then(res => {
      setprescricao_text('');
      ListarPrescrcao()
    });
  };

  async function UpdatePrescricao() {
    axios({
      method: 'put',
      url: Config.Url + 'EditarPrescricoes',
      data: {
        Id: edit,
        Texto: prescricao_text
      }
    }).then(res => {
      setprescricao_text('');
      setedit('')
      ListarPrescrcao()
    });
  };

  async function UpdateProntuario() {
    if (prescricao_text !== "") {
      await InsertPrescricao();
    };

    axios({
      method: 'put',
      url: Config.Url + 'EditarProntuarios',
      data: {
        Id: Id,
        Queixa_principal: queixa_principal,
        Quaixa_secundaria: quaixa_secundaria,
        Historico: historico,
        Emocional: emocional,
        Calor_frio: calor_frio,
        Traspiracao: traspiracao,
        Dor: dor,
        Cabeca: cabeca,
        Sist_musc_esq: sist_musc_esq,
        fezes_urina: fezas_urina,
        Sono: sono,
        Alimentos_sabor: alimentos_sabor,
        Sede_bebidas: sede_bebidas,
        Audição_tinidos: audição_tinidos,
        Aparelho_genitourinario: aparelho_genitourinario,
        Mestruacao: mestruacao,
        Lingua_cor: lingua_cor,
        Lingua_forma: lingua_forma,
        Lingua_saburra: lingua_saburra,
        Pulso: pulso,
        Es_r: es_r,
        Es_f: es_f,
        Es_c: es_c,
        Es_b: es_b,
        Es_vb: es_vb,
        Es_id: es_id,
        Dr_p: dr_p,
        Dr_bp: dr_bp,
        Dr_cs: dr_cs,
        Dr_ig: dr_ig,
        Dr_e: dr_e,
        Dr_ta: dr_ta,
        _8exterior_interior: _8_exterior_interior,
        _8_penitude_vazio: _8_penitude_vazio,
        _8_calor_frio: _8_calor_frio,
        _8_yin_yang: _8_yin_yang,
        Sub_qi: sub_qi,
        Sub_xue: sub_xue,
        Sub_jinye: sub_jinye,
        Sub_jing: sub_jing,
        Sub_shen: sub_shen,
        Zang_rim: zang_rim,
        Zang_figado: zang_figado,
        Zang_coracao: zang_coracao,
        Zang_baco: zang_baco,
        Zang_pulmao: zang_pulmao,
        Zang_visceras: zang_visceras,
        _5_elementos: _5_elementos,
        Diag_energetico: diag_energetico,
        Proposta_terapeutica: proposta_terapeutica
      }
    }).then(res => {
      if (res.data.sucesso === true) {
        Listar();
        ListarPacientes();
        setOpen(false);
        setAbre('')
        message.success('Prontuario atualizado com sucesso');
      } else {
        Listar();
        ListarPacientes();
        message.error('Falha ao atualizar prontuario');
      };
    });
  };

  useEffect(() => {
    Listar();
    ListarPacientes();
    ListarPrescrcao()
  }, [Buscar]);

  async function Delete(model) {
    api.destroy(model.Id)
    axios({
      method: 'delete',
      url: Config.Url + 'DeletarPrescricoes',
      data: {
        Id: model.Id
      }
    }).then(res => {
      if (res.data.sucesso === true) {
        ListarPrescrcao()
      } else {
        ListarPrescrcao()
      };
    });
  };
  const [api, contextHolder] = notification.useNotification();

  function MensagemDelete(model) {
    const btn = (
      <Space>
        <Button type="link" size="small" onClick={() => api.destroy(model.Id)}>
          Não
        </Button>
        <Button type="primary" size="small" onClick={() => Delete(model)} >
          Sim
        </Button>
      </Space>
    );
    api.open({
      message: 'Atenção',
      description:
        'Deseja excluir este registro ?',
      btn,
      key: model.Id
    });
  }


  return (
    <div className='ContainerHome'>
      {contextHolder}
      <div className='ContainerTableNav'>
        <Search
          style={{ marginLeft: 10, width: 350 }}
          placeholder="Buscar por nome"
          enterButton="Buscar"
          size="large"
          onSearch={onSearch}
        />
      </div>

      <div className='ContainerTable'>
        <Table dataSource={Pacientes} max columns={columns} />
      </div>



      <Drawer
        title={'Prontuario'}
        closable={false}
        width={'70%'}
        placement="right"
        open={Open}
        extra={
          <Space>
            <Button type="primary" danger onClick={() => setPdf(true)}>PDF</Button>
            <Button onClick={() => setOpen(false)}>Fechar</Button>
            <Button type="primary" onClick={UpdateProntuario}>Salvar</Button>
          </Space>
        }>
        <Form id='pronturario' layout="vertical" hideRequiredMark>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item >
                <center> <Avatar size={150} src={<Image style={{ width: 150, height: 150, borderRadius: 60 }} src={Foto === '' ? require('./../../Images/semimagem.png') : Config.Url + 'Pacientes/' + Foto} />} />                </center>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Nome Completo">
                <Input value={Nome} placeholder="Nome completo" disabled={true} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Nascimento">
                <Input value={Nascimento} placeholder="Nascimento" disabled={true} style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Sexo">
                <Input value={Sexo} placeholder="Sexo" disabled={true} style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Cpf">
                <Input value={Cpf} placeholder="Cpf" disabled={true} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item label="Estado Civil">
                <Input value={EstadoCivil} placeholder="Estado Civil" disabled={true} style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Idade">
                <Input value={Idade} placeholder="Idade" disabled={true} style={{ width: '100%' }} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label="Profissão">
                <Input value={Profissao} placeholder="Profissão" disabled={true} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["Queixa principal", abre === 'imprimir' ? null : <Button onClick={() => selecionar('Queixa principal')} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={abre === 'Queixa principal' ? <EyeInvisibleOutlined /> : <EyeOutlined />} />]} >
                <Input.TextArea placeholder="Queixa principal" value={queixa_principal} onChange={(e) => setqueixa_principal(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Queixa principal' || abre === 'imprimir' ? null : 'none' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["Quaixas Secundárias", abre === 'imprimir' ? null : <Button onClick={() => selecionar('Queixa secundaria')} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={abre === 'Queixa secundaria' ? <EyeInvisibleOutlined /> : <EyeOutlined />} />]} >
                <Input.TextArea placeholder="Quaixas Secundárias" value={quaixa_secundaria} onChange={(e) => setquaixa_secundaria(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Queixa secundaria' || abre === 'imprimir' ? null : 'none' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["Histórico", abre === 'imprimir' ? null : <Button onClick={() => selecionar('Histórico')} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={abre === 'Histórico' ? <EyeInvisibleOutlined /> : <EyeOutlined />} />]} >
                <Input.TextArea placeholder="Histórico" value={historico} onChange={(e) => sethistorico(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Histórico' || abre === 'imprimir' ? null : 'none' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["Emocional", abre === 'imprimir' ? null : <Button onClick={() => selecionar('Emocional')} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={abre === 'Emocional' ? <EyeInvisibleOutlined /> : <EyeOutlined />} />]} >
                <Input.TextArea placeholder="Emocional" value={emocional} onChange={(e) => setemocional(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Emocional' || abre === 'imprimir' ? null : 'none' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["Calor/Frio", abre === 'imprimir' ? null : <Button onClick={() => selecionar('Calor/Frio')} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={abre === 'Calor/Frio' ? <EyeInvisibleOutlined /> : <EyeOutlined />} />]} >
                <Input.TextArea placeholder="Calor/Frio" value={calor_frio} onChange={(e) => setcalor_frio(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Calor/Frio' || abre === 'imprimir' ? null : 'none' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["Dor", abre === 'imprimir' ? null : <Button onClick={() => selecionar('Dor')} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={abre === 'Dor' ? <EyeInvisibleOutlined /> : <EyeOutlined />} />]} >
                <Input.TextArea placeholder="Dor" value={dor} onChange={(e) => setdor(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Dor' || abre === 'imprimir' ? null : 'none' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["Cabeça", abre === 'imprimir' ? null : <Button onClick={() => selecionar('Cabeça')} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={abre === 'Cabeça' ? <EyeInvisibleOutlined /> : <EyeOutlined />} />]} >
                <Input.TextArea placeholder="Cabeça" value={cabeca} onChange={(e) => setcabeca(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Cabeça' || abre === 'imprimir' ? null : 'none' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["Sist Músc Esq", abre === 'imprimir' ? null : <Button onClick={() => selecionar('Sist Músc Esq')} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={abre === 'Sist Músc Esq' ? <EyeInvisibleOutlined /> : <EyeOutlined />} />]} >
                <Input.TextArea placeholder="Sist Músc Esq" value={sist_musc_esq} onChange={(e) => setsist_musc_esq(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Sist Músc Esq' || abre === 'imprimir' ? null : 'none' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["Sono", abre === 'imprimir' ? null : <Button onClick={() => selecionar('Sono')} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={abre === 'Sono' ? <EyeInvisibleOutlined /> : <EyeOutlined />} />]} >
                <Input.TextArea placeholder="Sono" value={sono} onChange={(e) => setsono(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Sono' || abre === 'imprimir' ? null : 'none' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["Alimentos e Sabor", abre === 'imprimir' ? null : <Button onClick={() => selecionar('Alimentos e Sabor')} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={abre === 'Alimentos e Sabor' ? <EyeInvisibleOutlined /> : <EyeOutlined />} />]} >
                <Input.TextArea placeholder="Alimentos e Sabor" value={alimentos_sabor} onChange={(e) => setalimentos_sabor(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Alimentos e Sabor' || abre === 'imprimir' ? null : 'none' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["Sede e Bebidas", abre === 'imprimir' ? null : <Button onClick={() => selecionar('Sede e Bebidas')} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={abre === 'Sede e Bebidas' ? <EyeInvisibleOutlined /> : <EyeOutlined />} />]} >
                <Input.TextArea placeholder="Sede e Bebidas" value={sede_bebidas} onChange={(e) => setsede_bebidas(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Sede e Bebidas' || abre === 'imprimir' ? null : 'none' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["Audição e Tinidos", abre === 'imprimir' ? null : <Button onClick={() => selecionar('Audição e Tinidos')} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={abre === 'Audição e Tinidos' ? <EyeInvisibleOutlined /> : <EyeOutlined />} />]} >
                <Input.TextArea placeholder="Audição e Tinidos" value={audição_tinidos} onChange={(e) => setaudição_tinidos(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Audição e Tinidos' || abre === 'imprimir' ? null : 'none' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["Aparelho Genitourinário", abre === 'imprimir' ? null : <Button onClick={() => selecionar('Aparelho Genitourinário')} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={abre === 'Aparelho Genitourinário' ? <EyeInvisibleOutlined /> : <EyeOutlined />} />]} >
                <Input.TextArea placeholder="Aparelho Genitourinário" value={aparelho_genitourinario} onChange={(e) => setaparelho_genitourinario(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Aparelho Genitourinário' || abre === 'imprimir' ? null : 'none' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["Menstruação", abre === 'imprimir' ? null : <Button onClick={() => selecionar('Menstruação')} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={abre === 'Menstruação' ? <EyeInvisibleOutlined /> : <EyeOutlined />} />]} >
                <Input.TextArea placeholder="Menstruação" value={mestruacao} onChange={(e) => setmestruacao(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Menstruação' || abre === 'imprimir' ? null : 'none' }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["Língua", abre === 'imprimir' ? null : <Button onClick={() => selecionar('Língua')} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={abre === 'Língua' ? <EyeInvisibleOutlined /> : <EyeOutlined />} />]} >
                {abre === 'Língua' || abre === 'imprimir' ?
                  <>
                    <Form.Item label={"Língua cor"} >
                      <Input.TextArea placeholder="Língua cor" value={lingua_cor} onChange={(e) => setlingua_cor(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Língua' || abre === 'imprimir' ? null : 'none' }} />
                    </Form.Item>
                    <Form.Item label={"Língua forma"} >
                      <Input.TextArea placeholder="Língua forma" value={lingua_forma} onChange={(e) => setlingua_forma(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Língua' || abre === 'imprimir' ? null : 'none' }} />
                    </Form.Item>
                    <Form.Item label={"Língua saburra"} >
                      <Input.TextArea placeholder="Língua saburra" value={lingua_saburra} onChange={(e) => setlingua_saburra(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Língua' || abre === 'imprimir' ? null : 'none' }} />
                    </Form.Item>
                  </>
                  : null}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["Pulso", abre === 'imprimir' ? null : <Button onClick={() => selecionar('Pulso')} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={abre === 'Pulso' ? <EyeInvisibleOutlined /> : <EyeOutlined />} />]} >
                {abre === 'Pulso' || abre === 'imprimir' ?
                  <>

                    <Row gutter={16}>
                      <Col span={8}>
                        <Form.Item label={["Pulso"]} >
                          <Input placeholder="Pulso" value={pulso} onChange={(e) => setpulso(e.target.value)} style={{ width: '100%', display: abre === 'Pulso' || abre === 'imprimir' ? null : 'none' }} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item style={{ marginBottom: -40 }} label={["Esquerdo"]} />

                    <Row gutter={16}>

                      <Col span={8}>
                        <Form.Item label={["R"]} >
                          <Input placeholder="R" value={es_r} onChange={(e) => setes_r(e.target.value)} style={{ width: '100%', display: abre === 'Pulso' || abre === 'imprimir' ? null : 'none' }} />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item label={["F"]} >
                          <Input placeholder="F" value={es_f} onChange={(e) => setes_f(e.target.value)} style={{ width: '100%', display: abre === 'Pulso' || abre === 'imprimir' ? null : 'none' }} />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item label={["C"]} >
                          <Input placeholder="C" value={es_c} onChange={(e) => setes_c(e.target.value)} style={{ width: '100%', display: abre === 'Pulso' || abre === 'imprimir' ? null : 'none' }} />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item label={["B"]} >
                          <Input placeholder="B" value={es_b} onChange={(e) => setes_b(e.target.value)} style={{ width: '100%', display: abre === 'Pulso' || abre === 'imprimir' ? null : 'none' }} />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item label={["VB"]} >
                          <Input placeholder="VB" value={es_vb} onChange={(e) => setes_vb(e.target.value)} style={{ width: '100%', display: abre === 'Pulso' || abre === 'imprimir' ? null : 'none' }} />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item label={["ID"]} >
                          <Input placeholder="ID" value={es_id} onChange={(e) => setes_id(e.target.value)} style={{ width: '100%', display: abre === 'Pulso' || abre === 'imprimir' ? null : 'none' }} />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Form.Item style={{ marginBottom: -40 }} label={["Direito"]} />

                    <Row gutter={16}>

                      <Col span={8}>
                        <Form.Item label={["P"]} >
                          <Input placeholder="P" value={dr_p} onChange={(e) => setdr_p(e.target.value)} style={{ width: '100%', display: abre === 'Pulso' || abre === 'imprimir' ? null : 'none' }} />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item label={["BP"]} >
                          <Input placeholder="BP" value={dr_bp} onChange={(e) => setdr_bp(e.target.value)} style={{ width: '100%', display: abre === 'Pulso' || abre === 'imprimir' ? null : 'none' }} />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item label={["CS"]} >
                          <Input placeholder="CS" value={dr_cs} onChange={(e) => setdr_cs(e.target.value)} style={{ width: '100%', display: abre === 'Pulso' || abre === 'imprimir' ? null : 'none' }} />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item label={["IG"]} >
                          <Input placeholder="IG" value={dr_ig} onChange={(e) => setdr_ig(e.target.value)} style={{ width: '100%', display: abre === 'Pulso' || abre === 'imprimir' ? null : 'none' }} />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item label={["E"]} >
                          <Input placeholder="E" value={dr_e} onChange={(e) => setdr_e(e.target.value)} style={{ width: '100%', display: abre === 'Pulso' || abre === 'imprimir' ? null : 'none' }} />
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item label={["TA"]} >
                          <Input placeholder="TA" value={dr_ta} onChange={(e) => setdr_ta(e.target.value)} style={{ width: '100%', display: abre === 'Pulso' || abre === 'imprimir' ? null : 'none' }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                  : null}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["8 Critérios", abre === 'imprimir' ? null : <Button onClick={() => selecionar('8 Critérios')} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={abre === '8 Critérios' ? <EyeInvisibleOutlined /> : <EyeOutlined />} />]} >
                {abre === '8 Critérios' || abre === 'imprimir' ?
                  <>
                    <Form.Item label={["Exterior/Interior"]} >
                      <Input.TextArea placeholder="Exterior/Interior" value={_8_exterior_interior} onChange={(e) => set_8_exterior_interior(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === '8 Critérios' || abre === 'imprimir' ? null : 'none' }} />
                    </Form.Item>

                    <Form.Item label={["Plenitude/Vazio"]} >
                      <Input.TextArea placeholder="Plenitude/Vazio" value={_8_penitude_vazio} onChange={(e) => set_8_penitude_vazio(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === '8 Critérios' || abre === 'imprimir' ? null : 'none' }} />
                    </Form.Item>

                    <Form.Item label={["Calor/Frio"]} >
                      <Input.TextArea placeholder="Calor/Frio" value={_8_calor_frio} onChange={(e) => set_8_calor_frio(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === '8 Critérios' || abre === 'imprimir' ? null : 'none' }} />
                    </Form.Item>

                    <Form.Item label={["Yin/Yang"]} >
                      <Input.TextArea placeholder="Yin/Yang" value={_8_yin_yang} onChange={(e) => set_8_yin_yang(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === '8 Critérios' || abre === 'imprimir' ? null : 'none' }} />
                    </Form.Item>
                  </>
                  : null}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["Substâncias Fundamentais", abre === 'imprimir' ? null : <Button onClick={() => selecionar('Substâncias Fundamentais')} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={abre === 'Substâncias Fundamentais' ? <EyeInvisibleOutlined /> : <EyeOutlined />} />]} >
                {abre === 'Substâncias Fundamentais' || abre === 'imprimir' ?
                  <>
                    <Form.Item label={["Qi"]} >
                      <Input.TextArea placeholder="Qi" value={sub_qi} onChange={(e) => setsub_qi(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Substâncias Fundamentais' || abre === 'imprimir' ? null : 'none' }} />
                    </Form.Item>

                    <Form.Item label={["Xue"]} >
                      <Input.TextArea placeholder="Xue" value={sub_xue} onChange={(e) => setsub_xue(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Substâncias Fundamentais' || abre === 'imprimir' ? null : 'none' }} />
                    </Form.Item>

                    <Form.Item label={["Jin Ye"]} >
                      <Input.TextArea placeholder="Jin Ye" value={sub_jinye} onChange={(e) => setsub_jinye(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Substâncias Fundamentais' || abre === 'imprimir' ? null : 'none' }} />
                    </Form.Item>

                    <Form.Item label={["Jing"]} >
                      <Input.TextArea placeholder="Jing" value={sub_jing} onChange={(e) => setsub_jing(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Substâncias Fundamentais' || abre === 'imprimir' ? null : 'none' }} />
                    </Form.Item>

                    <Form.Item label={["Shen"]} >
                      <Input.TextArea placeholder="Shen" value={sub_shen} onChange={(e) => setsub_shen(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Substâncias Fundamentais' || abre === 'imprimir' ? null : 'none' }} />
                    </Form.Item>
                  </>
                  : null}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["Zang Fu", abre === 'imprimir' ? null : <Button onClick={() => selecionar('Zang Fu')} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={abre === 'Zang Fu' ? <EyeInvisibleOutlined /> : <EyeOutlined />} />]} >
                {abre === 'Zang Fu' || abre === 'imprimir' ?
                  <>
                    <Form.Item label={["Rim"]} >
                      <Input.TextArea placeholder="Rim" value={zang_rim} onChange={(e) => setzang_rim(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Zang Fu' || abre === 'imprimir' ? null : 'none' }} />
                    </Form.Item>

                    <Form.Item label={["Figado"]} >
                      <Input.TextArea placeholder="Figado" value={zang_figado} onChange={(e) => setzang_figado(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Zang Fu' || abre === 'imprimir' ? null : 'none' }} />
                    </Form.Item>

                    <Form.Item label={["Coração"]} >
                      <Input.TextArea placeholder="Coração" value={zang_coracao} onChange={(e) => setzang_coracao(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Zang Fu' || abre === 'imprimir' ? null : 'none' }} />
                    </Form.Item>

                    <Form.Item label={["Baço"]} >
                      <Input.TextArea placeholder="Baço" value={zang_baco} onChange={(e) => setzang_baco(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Zang Fu' || abre === 'imprimir' ? null : 'none' }} />
                    </Form.Item>

                    <Form.Item label={["Pulmão"]} >
                      <Input.TextArea placeholder="Pulmão" value={zang_pulmao} onChange={(e) => setzang_pulmao(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Zang Fu' || abre === 'imprimir' ? null : 'none' }} />
                    </Form.Item>

                    <Form.Item label={["Víceras"]} >
                      <Input.TextArea placeholder="Víceras" value={zang_visceras} onChange={(e) => setzang_visceras(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Zang Fu' || abre === 'imprimir' ? null : 'none' }} />
                    </Form.Item>
                  </>
                  : null}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["5 Elementos", abre === 'imprimir' ? null : <Button onClick={() => selecionar('5 Elementos')} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={abre === '5 Elementos' ? <EyeInvisibleOutlined /> : <EyeOutlined />} />]} >
                {abre === '5 Elementos' || abre === 'imprimir' ?
                  <>
                    <Form.Item label={["5 Elementos"]} >
                      <Input.TextArea placeholder="5 Elementos" value={_5_elementos} onChange={(e) => set_5_elementos(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === '5 Elementos' || abre === 'imprimir' ? null : 'none' }} />
                    </Form.Item>
                  </>
                  : null}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["Diagnóstico Energético", abre === 'imprimir' ? null : <Button onClick={() => selecionar('Diagnóstico Energético')} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={abre === 'Diagnóstico Energético' ? <EyeInvisibleOutlined /> : <EyeOutlined />} />]} >
                {abre === 'Diagnóstico Energético' || abre === 'imprimir' ?
                  <>
                    <Form.Item label={["Diagnóstico Energético"]} >
                      <Input.TextArea placeholder="Diagnóstico Energético" value={diag_energetico} onChange={(e) => setdiag_energetico(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Diagnóstico Energético' || abre === 'imprimir' ? null : 'none' }} />
                    </Form.Item>
                  </>
                  : null}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["Proposta Terapêutica", abre === 'imprimir' ? null : <Button onClick={() => selecionar('Proposta Terapêutica')} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={abre === 'Proposta Terapêutica' ? <EyeInvisibleOutlined /> : <EyeOutlined />} />]} >
                {abre === 'Proposta Terapêutica' || abre === 'imprimir' ?
                  <>
                    <Form.Item label={["Proposta Terapêutica"]} >
                      <Input.TextArea placeholder="Diagnóstico Energético" value={proposta_terapeutica} onChange={(e) => setproposta_terapeutica(e.target.value)} autoSize={{ minRows: 5, maxRows: 20 }} style={{ width: '100%', display: abre === 'Proposta Terapêutica' || abre === 'imprimir' ? null : 'none' }} />
                    </Form.Item>
                  </>
                  : null}
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label={["Evolução", abre === 'imprimir' ? null : <Button onClick={() => setAbrirPrescricoes(true)} style={{ marginLeft: 15, display: Prescricoes.filter(e => e.Prontuario === Id).length === 0 ? 'none' : 'flex', width: 50, height: 25, alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={<FilePptOutlined />} />]} >
                <Input.TextArea placeholder="Evolução" value={prescricao_text} onChange={(e) => setprescricao_text(e.target.value)} autoSize={{ minRows: 10, maxRows: 20 }} style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

        </Form>
      </Drawer>

      <Drawer
        title={'Prescrições'}
        closable={false}
        width={'70%'}
        placement="right"
        open={AbrirPrescricoes}
        extra={
          <Space>
            <Button onClick={() => [setAbrirPrescricoes(false), setedit('')]}>Fechar</Button>
          </Space>
        }>

        {Prescricoes.filter(e => e.Prontuario === Id).map((e, index) => (
          <Form id='pronturario' layout="vertical" hideRequiredMark>
            <Row gutter={16}>
              <Col span={24}>
                <Form.Item label={[moment(e.createdAt).tz("America/Sao_Paulo").format('DD/MM/YYYY HH:mm'), abre === 'imprimir' ? null : <Button onClick={() => [prescricao_text !== '' ? null : edit === e.Id ? UpdatePrescricao() : setedit(e.Id), setprescricao_text(e.Texto)]} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={e.Id === edit ? <CheckOutlined /> : <EditOutlined />} />, <Button onClick={() => MensagemDelete(e)} style={{ marginLeft: 15, width: 50, height: 25, display: 'flex', backgroundColor: 'red', alignItems: 'center', justifyContent: 'center', borderRadius: 50 }} type="primary" icon={<DeleteOutlined />} />]} >
                  <Input.TextArea disabled={e.Id === edit ? false : true} placeholder="Evolução" value={e.Id === edit ? prescricao_text : e.Texto} onChange={(e) => setprescricao_text(e.target.value)} autoSize={{ minRows: 10, maxRows: 20 }} style={{ width: '100%' }} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        ))}

      </Drawer>




      <Modal width={1000} open={Pdf} onCancel={() => setPdf(false)} footer={[]} closable={false}>
        <div style={{ height: 800 }}>
          <PDFViewer style={{ width: '100%', height: '100%' }}>
            <Document>
              <Page size="A4" style={styles.page}>
                <div style={{ width: '100%', padding: 25, height: 180, backgroundColor: '#3c5270', alignItems: 'center' }}>
                  <Text style={{ color: '#fff', fontWeight: 'bold' }}>{Nome}</Text>
                  <View style={styles.section}>
                    <Text style={{ fontSize: 15, marginBottom: 10, color: '#fff' }}>Profissional: {localStorage.getItem('Nome')}</Text>

                    <Text style={{ fontSize: 15, marginBottom: 10, color: '#fff' }}>Empresa: {localStorage.getItem('Empresa')}</Text>

                    <Text style={{ fontSize: 15, marginBottom: 10, color: '#fff' }}>Telefone: {localStorage.getItem('Telefone')}</Text>

                    <Text style={{ fontSize: 15, marginBottom: 10, color: '#fff' }}>Cidade: {localStorage.getItem('Cidade')}</Text>

                    <Text style={{ fontSize: 15, marginBottom: 10, color: '#fff' }}>Gerado em: {moment().tz("America/Sao_Paulo").format('DD/MM/YYYY HH:mm:ss')}</Text>
                  </View>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '30%', minHeight: 40, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#eee' }}>
                    <Text style={{ fontSize: 13 }}>Queixa Principal</Text>
                  </div>
                  <div style={{ width: '70%', minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                    <Text style={{ fontSize: 13 }}>{queixa_principal}</Text>
                  </div>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '30%', minHeight: 40, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#eee' }}>
                    <Text style={{ fontSize: 13 }}>Quaixas Secundárias</Text>
                  </div>
                  <div style={{ width: '70%', minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                    <Text style={{ fontSize: 13 }}>{quaixa_secundaria}</Text>
                  </div>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '30%', minHeight: 40, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#eee' }}>
                    <Text style={{ fontSize: 13 }}>Histórico</Text>
                  </div>
                  <div style={{ width: '70%', minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                    <Text style={{ fontSize: 13 }}>{historico}</Text>
                  </div>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '30%', minHeight: 40, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#eee' }}>
                    <Text style={{ fontSize: 13 }}>Emocional</Text>
                  </div>
                  <div style={{ width: '70%', minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                    <Text style={{ fontSize: 13 }}>{emocional}</Text>
                  </div>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '30%', minHeight: 40, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#eee' }}>
                    <Text style={{ fontSize: 13 }}>Color/Frio</Text>
                  </div>
                  <div style={{ width: '70%', minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                    <Text style={{ fontSize: 13 }}>{calor_frio}</Text>
                  </div>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '30%', minHeight: 40, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#eee' }}>
                    <Text style={{ fontSize: 13 }}>Dor</Text>
                  </div>
                  <div style={{ width: '70%', minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                    <Text style={{ fontSize: 13 }}>{dor}</Text>
                  </div>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '30%', minHeight: 40, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#eee' }}>
                    <Text style={{ fontSize: 13 }}>Cabeça</Text>
                  </div>
                  <div style={{ width: '70%', minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                    <Text style={{ fontSize: 13 }}>{cabeca}</Text>
                  </div>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '30%', minHeight: 40, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#eee' }}>
                    <Text style={{ fontSize: 13 }}>Sist Musc Esq</Text>
                  </div>
                  <div style={{ width: '70%', minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                    <Text style={{ fontSize: 13 }}>{sist_musc_esq}</Text>
                  </div>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '30%', minHeight: 40, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#eee' }}>
                    <Text style={{ fontSize: 13 }}>Sono</Text>
                  </div>
                  <div style={{ width: '70%', minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                    <Text style={{ fontSize: 13 }}>{sono}</Text>
                  </div>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '30%', minHeight: 40, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#eee' }}>
                    <Text style={{ fontSize: 13 }}>Alimentos e Sabor</Text>
                  </div>
                  <div style={{ width: '70%', minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                    <Text style={{ fontSize: 13 }}>{alimentos_sabor}</Text>
                  </div>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '30%', minHeight: 40, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#eee' }}>
                    <Text style={{ fontSize: 13 }}>Sede e Bebidas</Text>
                  </div>
                  <div style={{ width: '70%', minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                    <Text style={{ fontSize: 13 }}>{sede_bebidas}</Text>
                  </div>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '30%', minHeight: 40, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#eee' }}>
                    <Text style={{ fontSize: 13 }}>Audição e Tinidos</Text>
                  </div>
                  <div style={{ width: '70%', minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                    <Text style={{ fontSize: 13 }}>{audição_tinidos}</Text>
                  </div>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '30%', minHeight: 40, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#eee' }}>
                    <Text style={{ fontSize: 13 }}>Aparelho Genitourinário</Text>
                  </div>
                  <div style={{ width: '70%', minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                    <Text style={{ fontSize: 13 }}>{aparelho_genitourinario}</Text>
                  </div>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '30%', minHeight: 40, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#eee' }}>
                    <Text style={{ fontSize: 13 }}>Menstruação</Text>
                  </div>
                  <div style={{ width: '70%', minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                    <Text style={{ fontSize: 13 }}>{mestruacao}</Text>
                  </div>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '30%', minHeight: 40, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#eee' }}>
                    <Text style={{ fontSize: 13 }}>Língua</Text>
                  </div>
                  <div style={{ width: '70%', minHeight: 40 }}>
                    <div style={{ width: '100%', marginBottom: 10, minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                      <Text style={{ fontSize: 13 }}>Língua cor: {lingua_cor}</Text>
                    </div>
                    <div style={{ width: '100%', marginBottom: 10, minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                      <Text style={{ fontSize: 13 }}>Língua forma: {lingua_forma}</Text>
                    </div>
                    <div style={{ width: '100%', marginBottom: 10, minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                      <Text style={{ fontSize: 13 }}>Língua saburra: {lingua_saburra}</Text>
                    </div>
                  </div>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '30%', minHeight: 40, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#eee' }}>
                    <Text style={{ fontSize: 13 }}>Pulso</Text>
                  </div>
                  <div style={{ width: '70%', minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                    <div style={{ width: '100%', minHeight: 40, padding: 5, backgroundColor: '#eee' }}>
                      <Text style={{ fontSize: 13 }}>{pulso}</Text>
                    </div>

                    <div style={{ width: '100%', minHeight: 40, marginTop: 10, display: "flex", flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', padding: 5, backgroundColor: '#eee' }}>
                      <div style={{ width: '30%', marginBottom: 10, minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                        <Text style={{ fontSize: 13 }}>R: {es_r}</Text>
                      </div>
                      <div style={{ width: '30%', marginBottom: 10, minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                        <Text style={{ fontSize: 13 }}>F: {es_f}</Text>
                      </div>
                      <div style={{ width: '30%', marginBottom: 10, minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                        <Text style={{ fontSize: 13 }}>C: {es_c}</Text>
                      </div>

                      <div style={{ width: '30%', minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                        <Text style={{ fontSize: 13 }}>B: {es_b}</Text>
                      </div>
                      <div style={{ width: '30%', minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                        <Text style={{ fontSize: 13 }}>VB: {es_vb}</Text>
                      </div>
                      <div style={{ width: '30%', minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                        <Text style={{ fontSize: 13 }}>ID: {es_id}</Text>
                      </div>
                    </div>

                    <div style={{ width: '100%', minHeight: 40, marginTop: 10, display: "flex", flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', padding: 5, backgroundColor: '#eee' }}>
                      <div style={{ width: '30%', marginBottom: 10, minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                        <Text style={{ fontSize: 13 }}>P: {dr_p}</Text>
                      </div>
                      <div style={{ width: '30%', marginBottom: 10, minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                        <Text style={{ fontSize: 13 }}>BP: {dr_bp}</Text>
                      </div>
                      <div style={{ width: '30%', marginBottom: 10, minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                        <Text style={{ fontSize: 13 }}>CS: {dr_cs}</Text>
                      </div>

                      <div style={{ width: '30%', minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                        <Text style={{ fontSize: 13 }}>IG: {dr_ig}</Text>
                      </div>
                      <div style={{ width: '30%', minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                        <Text style={{ fontSize: 13 }}>E: {dr_e}</Text>
                      </div>
                      <div style={{ width: '30%', minHeight: 40, padding: 5, backgroundColor: '#fff' }}>
                        <Text style={{ fontSize: 13 }}>TA: {dr_ta}</Text>
                      </div>
                    </div>

                  </div>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '30%', minHeight: 40, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#eee' }}>
                    <Text style={{ fontSize: 13 }}>8 Critérios</Text>
                  </div>
                  <div style={{ width: '70%', minHeight: 40, backgroundColor: '#eee' }}>

                    <div style={{ width: '100%', minHeight: 40, marginTop: 10, padding: 5, backgroundColor: '#fff' }}>
                      <Text style={{ fontSize: 13 }}>Exterior/Interior: {_8_exterior_interior}</Text>
                    </div>

                    <div style={{ width: '100%', minHeight: 40, marginTop: 10, padding: 5, backgroundColor: '#fff' }}>
                      <Text style={{ fontSize: 13 }}>Plenitude/Vazio: {_8_penitude_vazio}</Text>
                    </div>

                    <div style={{ width: '100%', minHeight: 40, marginTop: 10, padding: 5, backgroundColor: '#fff' }}>
                      <Text style={{ fontSize: 13 }}>Calor/Frio: {_8_calor_frio}</Text>
                    </div>

                    <div style={{ width: '100%', minHeight: 40, marginTop: 10, padding: 5, backgroundColor: '#fff' }}>
                      <Text style={{ fontSize: 13 }}>Yin/Yang: {_8_yin_yang}</Text>
                    </div>

                  </div>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '30%', minHeight: 40, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#eee' }}>
                    <Text style={{ fontSize: 13 }}>Substâncias Fundamentais</Text>
                  </div>
                  <div style={{ width: '70%', minHeight: 40, backgroundColor: '#eee' }}>

                    <div style={{ width: '100%', minHeight: 40, marginTop: 10, padding: 5, backgroundColor: '#fff' }}>
                      <Text style={{ fontSize: 13 }}>Qi: {sub_qi}</Text>
                    </div>

                    <div style={{ width: '100%', minHeight: 40, marginTop: 10, padding: 5, backgroundColor: '#fff' }}>
                      <Text style={{ fontSize: 13 }}>Xue: {sub_xue}</Text>
                    </div>

                    <div style={{ width: '100%', minHeight: 40, marginTop: 10, padding: 5, backgroundColor: '#fff' }}>
                      <Text style={{ fontSize: 13 }}>Jin Ye: {sub_jinye}</Text>
                    </div>

                    <div style={{ width: '100%', minHeight: 40, marginTop: 10, padding: 5, backgroundColor: '#fff' }}>
                      <Text style={{ fontSize: 13 }}>Jing: {sub_jing}</Text>
                    </div>

                    <div style={{ width: '100%', minHeight: 40, marginTop: 10, padding: 5, backgroundColor: '#fff' }}>
                      <Text style={{ fontSize: 13 }}>Shen: {sub_shen}</Text>
                    </div>

                  </div>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '30%', minHeight: 40, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#eee' }}>
                    <Text style={{ fontSize: 13 }}>Zang Fu</Text>
                  </div>
                  <div style={{ width: '70%', minHeight: 40, backgroundColor: '#eee' }}>

                    <div style={{ width: '100%', minHeight: 40, marginTop: 10, padding: 5, backgroundColor: '#fff' }}>
                      <Text style={{ fontSize: 13 }}>Rim: {zang_rim}</Text>
                    </div>

                    <div style={{ width: '100%', minHeight: 40, marginTop: 10, padding: 5, backgroundColor: '#fff' }}>
                      <Text style={{ fontSize: 13 }}>Fígado: {zang_figado}</Text>
                    </div>

                    <div style={{ width: '100%', minHeight: 40, marginTop: 10, padding: 5, backgroundColor: '#fff' }}>
                      <Text style={{ fontSize: 13 }}>Coração: {zang_coracao}</Text>
                    </div>

                    <div style={{ width: '100%', minHeight: 40, marginTop: 10, padding: 5, backgroundColor: '#fff' }}>
                      <Text style={{ fontSize: 13 }}>Baço: {zang_baco}</Text>
                    </div>

                    <div style={{ width: '100%', minHeight: 40, marginTop: 10, padding: 5, backgroundColor: '#fff' }}>
                      <Text style={{ fontSize: 13 }}>Pulmão: {zang_pulmao}</Text>
                    </div>

                    <div style={{ width: '100%', minHeight: 40, marginTop: 10, padding: 5, backgroundColor: '#fff' }}>
                      <Text style={{ fontSize: 13 }}>Víceras: {zang_visceras}</Text>
                    </div>

                  </div>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '30%', minHeight: 40, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#eee' }}>
                    <Text style={{ fontSize: 13 }}>5 Elementos</Text>
                  </div>
                  <div style={{ width: '70%', minHeight: 40, backgroundColor: '#fff' }}>

                    <div style={{ width: '100%', minHeight: 40, marginTop: 10, padding: 5, backgroundColor: '#fff' }}>
                      <Text style={{ fontSize: 13 }}> {_5_elementos}</Text>
                    </div>

                  </div>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '30%', minHeight: 40, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#eee' }}>
                    <Text style={{ fontSize: 13 }}>Diagnóstico Energético</Text>
                  </div>
                  <div style={{ width: '70%', minHeight: 40, backgroundColor: '#fff' }}>

                    <div style={{ width: '100%', minHeight: 40, marginTop: 10, padding: 5, backgroundColor: '#fff' }}>
                      <Text style={{ fontSize: 13 }}>{diag_energetico}</Text>
                    </div>

                  </div>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '30%', minHeight: 40, display: 'flex', alignItems: 'flex-start', justifyContent: 'center', backgroundColor: '#eee' }}>
                    <Text style={{ fontSize: 13 }}>Proposta Terapêutica</Text>
                  </div>
                  <div style={{ width: '70%', minHeight: 40, backgroundColor: '#fff' }}>

                    <div style={{ width: '100%', minHeight: 40, marginTop: 10, padding: 5, backgroundColor: '#fff' }}>
                      <Text style={{ fontSize: 13 }}>{proposta_terapeutica}</Text>
                    </div>

                  </div>
                </div>

                <div style={{ width: '100%', minHeight: 40, padding: 5, display: 'flex', flexDirection: 'row', backgroundColor: '#eee' }}>
                  <div style={{ width: '100%', minHeight: 40, backgroundColor: '#fff' }}>
                    {Prescricoes.filter(e=>e.Prontuario === Id).map((e, index) => (
                      <div key={index} style={{ width: '100%', minHeight: 40, marginTop: 10, padding: 5, backgroundColor: '#fff' }}>
                        <Text style={{ fontSize: 13 }}>{e.Texto}</Text>
                      </div>
                    ))}
                  </div>
                </div>


              </Page>
            </Document>
          </PDFViewer>
        </div>
      </Modal>


    </div>
  );
};


// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#fff'
  },
  section: {
    marginTop: 25,
    width: '100%',
  },
  table: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTop: '1px solid #EEE',
    paddingBottom: 8,
  },
  rows: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTop: '1px solid #EEE',
    marginBottom: 1

  }
});


export default Prontuarios;
