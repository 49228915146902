import { LogoutOutlined, MenuOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import './header.css';
import axios from 'axios';
import Config from '../../Configuracoes/config';
import { useEffect, useState } from 'react';

function Header({ Open, setOpen }) {
    const navigate = useNavigate();

    function Sair() {
        localStorage.clear();
        navigate('/');
    };

    function Live() {
        navigate('/dashboard/live');
    };

    const [LiveOn, setLiveOn] = useState([]);

    async function BuscarLive() {
        await axios({
            method: 'post',
            url: Config.Url + 'ListarLiveAtivas'
        }).then(e => e.data)
            .then(e => {
                if (e.sucesso) {
                    setLiveOn(e.result);
                } else {
                    setLiveOn([]);
                };
            });
    };


    const [Contador, setContador] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setContador(Contador + 1)
        }, 2000);
        BuscarLive();
    }, [Contador])

    return (
        <div className='Header'>

            <div className='Header_1'>
                <Space>
                    <Button onClick={() => setOpen(!Open)} type="primary" size="large" style={{ width: 50, height: 50, borderRadius: 5, backgroundColor: '#1A2B52', color: '#FFFFFF', fontSize: 23 }} icon={<MenuOutlined />} />
                    <h1 style={{ color: '#FFFFFF' }}>Acupuntura Pro</h1>

                    {LiveOn.length > 0  && <Button onClick={() => Live()} type="primary" size="large" style={{ width: 50, height: 50, borderRadius: 5, backgroundColor: '#1A2B52', color: '#f00000', fontSize: 23 }} icon={<PlayCircleOutlined />} >
                        Estamos ao vivo
                    </Button>}


                </Space>
            </div>

            <div className='Header_2'>
            </div>

            <div className='Header_3'>

            </div>

            <div className='Header_4'>
                <Space>


                    <Button onClick={Sair} type="primary" size="large" style={{ width: 120, height: 50, borderRadius: 5, backgroundColor: '#1A2B52', fontSize: 18 }} icon={<LogoutOutlined />}  >
                        Sair
                    </Button>

                    {/* <Button type="primary" size="large" style={{ width: 50, height: 50, borderRadius: 5, backgroundColor: '#445375', fontSize: 23 }} icon={<MdPersonPin style={{ fontSize: 32 }} />} /> */}

                </Space>
            </div>

        </div>
    );
}

export default Header;
